import React, { useState } from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { createProductCustomAttribute } from "applications/Cms/api/productCustomAttributes"
import { Formik, useFormikContext } from "formik"
import {
  CatalogCustomAttribute,
  CatalogProduct,
} from "applications/Cms/types/sharedTypes"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { getAvailableAttributes } from "./api"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import CmsDrawer from "../../../../../components/CmsDrawer"
import { CanopySelectField } from "@parachutehealth/canopy-select-field"
import { getCatalogAttribute } from "../../../../../api/attributes"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import FreesoloCombobox from "../../../../../components/FreesoloCombobox"
import get from "lodash/get"
import pick from "lodash/pick"
import { isNullOrUndefined } from "../../../../../../../utilities/isNullOrUndefined"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { attributesCreateFormPageUrl } from "../../../../../urls/attributes"
import isEmpty from "lodash/isEmpty"
import sortedUniqNumbers from "utilities/array/sortedUniqNumbers"
import { AxiosError } from "axios"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { canopyColorPrimitivesBlue86 } from "@parachutehealth/canopy-tokens-color"

export type Props = {
  onSave?: () => void
  product: CatalogProduct
  open?: boolean // for testing
}

type FormValues = {
  customAttribute: { id?: number; text: string }
  defaultAttributeValue: { id?: number; text: string }
}

/* This represents all the fields that represent "default" values when the presence of SKUs demands it */
const defaultValueFields = Object.freeze([
  "defaultCustomAttributeOption",
  "defaultCustomAttributeOptionPackagingLabelId",
  "defaultCustomAttributeOptionUnitId",
  "defaultCustomAttributeOptionValue",
])

const AddProductAttributeForm: React.FC<Props> = (
  props: Props
): React.JSX.Element => {
  const { product, open, onSave } = props

  const availableAttributesQueryKey = [
    "ProductDetailsPage",
    "AddProductAttributeForm",
    "getAvailableAttributes",
    product.externalId,
  ]

  const [dialogOpen, setDialogOpen] = useState<boolean>(!!open)
  const queryClient = useQueryClient()
  const {
    isFetching: availableAttributesIsFetching = true,
    data: availableAttributeResponse,
  } = useQuery({
    queryKey: availableAttributesQueryKey,
    queryFn: () => getAvailableAttributes(product.externalId),
    refetchOnWindowFocus: false,
    enabled: dialogOpen,
  })

  const handleClickOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  const tearDown = () => {
    void queryClient.invalidateQueries({
      // using only this prefix should reset all the react-query instances for this page
      // other items like loaded SKUs will need to be re-queried if their attributes change
      queryKey: ["ProductDetailsPage"],
    })
  }

  const handleSubmit = (
    params: Record<string, any>,
    { setSubmitting, setFieldError, resetForm }
  ) => {
    setSubmitting(true)
    createProductCustomAttribute(
      product.id,
      params.customAttribute.value,
      pick(params, defaultValueFields)
    )
      .then(() => {
        setDialogOpen(false)
        if (onSave) onSave()
        tearDown()
        resetForm()
      })
      .catch((error: AxiosError) => {
        const errors = get(error, ["response", "data", "errors"], {})

        const customAttributeErr = errors.customAttributeId?.includes(
          "can't be blank"
        )
          ? "Field is required"
          : errors.customAttributeId
        setFieldError("customAttribute", customAttributeErr)

        const defaultCustomAttributeErr = errors.base?.includes(
          "You must provide either a description or a custom_attribute_option_value"
        )
          ? "Field is required when SKUs are present"
          : errors.defaultCustomAttributeOption
        setFieldError("defaultCustomAttributeOption", defaultCustomAttributeErr)

        const fieldErrorMapping = {
          value: "defaultCustomAttributeOptionValue",
          description: "defaultCustomAttributeOption",
        }

        Object.entries(errors).forEach(([key, value]) => {
          // certain items will be returned as validation errors under certain keys
          // but need to be mapped to differently-named form fields
          if (fieldErrorMapping[key]) {
            setFieldError(fieldErrorMapping[key], errors[key])
          } else {
            setFieldError(key, value)
          }
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  /**
   * The subcomponent encapsulates the Combobox where the user either selects an existing attribute.
   */
  const CustomAttributeAutocomplete = () => {
    const options =
      availableAttributeResponse?.map((attr) => ({
        value: attr.id.toString(),
        label: attr.name,
      })) || []
    const fieldName = "customAttribute"

    const { setFieldValue, values } = useFormikContext<Record<string, any>>()
    const newAttribute = Boolean(get(values, ["customAttribute", "freeSolo"]))

    const newAttributeMessage = (
      <>
        This Attribute does not yet exist in the Catalog. Check your spelling or{" "}
        <CanopyLink target="_blank" href={attributesCreateFormPageUrl()}>
          Create a new global Attribute
        </CanopyLink>{" "}
        in the Attribute library
      </>
    )

    const onChange = (option) => {
      // if we change the attribute midstream, make sure we reset any items
      // that might be hidden as a result
      if (values[fieldName] !== option) {
        defaultValueFields.forEach((f) => setFieldValue(f, null))
      }
      setFieldValue(fieldName, option)
    }

    return (
      <FreesoloCombobox
        className="canopy-mbe-8x"
        id="addProductCustomAttributeField"
        data-testid="addProductCustomAttributeField"
        placeholder="Input an attribute"
        options={options}
        // Canopy doesn't allow rich text so we trick it b/c we want a link
        feedbackMessage={
          newAttribute
            ? ((newAttributeMessage as unknown) as string)
            : undefined
        }
        required={true}
        label="Attribute"
        onChange={onChange}
      />
    )
  }

  /**
   * Note: this is essentially a copy of the logic in `::Catalog::NumericDescriptionBuilder`
   * The two will need to be kept in sync
   */
  const NumericDescriptionPreview = ({
    customAttribute,
  }: {
    customAttribute: CatalogCustomAttribute
  }): React.JSX.Element => {
    const { values } = useFormikContext<FormValues>()

    const defaultOptionValue = get(values, "defaultCustomAttributeOptionValue")

    if (customAttribute.valueType !== "number" || !defaultOptionValue) {
      return <></>
    }

    const packagingLabel = customAttribute.packagingLabels?.find(
      (pl) =>
        pl.id.toString() ===
        get(values, "defaultCustomAttributeOptionPackagingLabelId")
    )
    const unit = customAttribute.units?.find(
      (u) =>
        u.id.toString() === get(values, "defaultCustomAttributeOptionUnitId")
    )

    let preview: string
    if (packagingLabel && unit) {
      preview = `${packagingLabel.label} (${defaultOptionValue} ${unit.label})`
    } else if (unit) {
      preview = `${defaultOptionValue} ${unit.label}`
    } else if (packagingLabel) {
      preview = `${packagingLabel.label} (${defaultOptionValue})`
    } else {
      preview = defaultOptionValue
    }

    const fullPreview = (
      <>
        This will show as{" "}
        <span
          className="canopy-py-4x canopy-px-6x"
          style={{
            borderRadius: "1rem",
            backgroundColor: canopyColorPrimitivesBlue86,
          }}
        >
          {preview}
        </span>{" "}
        in the ordering experience
      </>
    )

    return (
      <CanopyNotice
        className="canopy-my-6x"
        // need to trick Canopy into accepting nested markup
        title={(fullPreview as unknown) as string}
        variant="information"
      />
    )
  }

  const DefaultAttributeValueField = ({
    customAttribute,
  }: {
    customAttribute: CatalogCustomAttribute
  }) => {
    const { values, errors, setFieldValue } = useFormikContext<FormValues>()

    const labelsToOptions = (
      items: { id: number; label: string }[]
    ): OptionItem[] => {
      return items.map((unit) => ({
        value: unit.id.toString(),
        label: unit.label,
      }))
    }

    if (customAttribute.valueType === "number") {
      const packagingLabelKey = "defaultCustomAttributeOptionPackagingLabelId"
      const packagingOptions = labelsToOptions(
        customAttribute.packagingLabels || []
      )

      const unitKey = "defaultCustomAttributeOptionUnitId"
      const unitOptions = labelsToOptions(customAttribute.units || [])

      const valueKey = "defaultCustomAttributeOptionValue"
      const optionValues =
        customAttribute.options?.filter((o) => o.value)?.map((o) => o.value!) ||
        []

      // we can now have multiple existing options with the same value but different units/packaging, and we don't want
      // multiple copies of the same option showing up
      const uniqueValues: string[] = sortedUniqNumbers(optionValues)

      return (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CanopyComboboxField
                onChange={(newValue) =>
                  setFieldValue(packagingLabelKey, get(newValue, "value"))
                }
                options={packagingOptions}
                disabled={isEmpty(packagingOptions)}
                multiple={false}
                size="small"
                placeholder={isEmpty(packagingOptions) ? "N/A" : undefined}
                value={get(values, packagingLabelKey)}
                label="Default packaging label"
                feedbackMessage={errors[packagingLabelKey]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FreesoloCombobox
                label="Default value"
                required={skusPresent}
                feedbackMessage={errors[valueKey]}
                defaultValue={get(values, valueKey)}
                options={uniqueValues}
                onChange={(newValue) =>
                  setFieldValue(valueKey, newValue?.value || newValue)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CanopyComboboxField
                onChange={(newValue) =>
                  setFieldValue(unitKey, get(newValue, "value"))
                }
                options={unitOptions}
                disabled={isEmpty(unitOptions)}
                multiple={false}
                size="small"
                placeholder={isEmpty(unitOptions) ? "N/A" : undefined}
                value={get(values, unitKey)}
                label="Default unit of measure"
                feedbackMessage={errors[unitKey]}
              />
            </Grid>
          </Grid>

          <NumericDescriptionPreview customAttribute={customAttribute} />
        </>
      )
    } else {
      const options =
        customAttribute.options?.map((o) => ({
          label: o.description,
          value: o.id.toString(),
        })) || []

      return (
        <FreesoloCombobox
          className="canopy-mbe-8x"
          label="Default description"
          description="Input a default Attribute for existing SKUs"
          required={skusPresent}
          feedbackMessage={errors["defaultCustomAttributeOption"]}
          options={options}
          onChange={(newValue) =>
            setFieldValue(
              "defaultCustomAttributeOption",
              newValue?.label || newValue
            )
          }
        />
      )
    }
  }

  const Loader = () => {
    return (
      <CanopyFlex className="canopy-m-4x" justifyContent="center">
        <CircularProgress color="inherit" />
      </CanopyFlex>
    )
  }

  const AttributeDetailFields = (): React.JSX.Element => {
    const { values } = useFormikContext()

    const attributeId = get(values, ["customAttribute", "value"])
    // the FreesoloCombobox will return a `freeSolo` attribute on newly-created entries
    const newAttribute = get(values, ["customAttribute", "freeSolo"]) || false

    const {
      isFetching: attributeIsFetching = true,
      data: attributeResponse,
    } = useQuery({
      queryKey: [
        "ProductDetailsPage",
        "AddProductAttributeForm",
        "getCatalogAttribute",
        attributeId,
      ],
      queryFn: () => getCatalogAttribute(attributeId),
      refetchOnWindowFocus: false,
      enabled: !isNullOrUndefined(attributeId) && !newAttribute,
    })

    // this doesn't show anything until an attribute has been selected or freesolo'd
    if (isEmpty(get(values, "customAttribute"))) {
      return <></>
    }

    if (attributeIsFetching) {
      return <Loader />
    }

    return (
      <>
        <CanopySelectField
          size="small"
          className="canopy-mbe-8x"
          label="Attribute type"
          disabled // this is for display only
          defaultValue={attributeResponse?.valueType}
          required
          options={[
            { label: "Text", value: "text" },
            { label: "Number", value: "number" },
          ]}
        />

        {attributeResponse && (
          <DefaultAttributeValueField customAttribute={attributeResponse} />
        )}
      </>
    )
  }

  const skusPresent = (product["skuCount"] || 0) > 0

  // this is its own component in order to have access to Formik context
  const SubmitButton = (): React.JSX.Element => {
    const { values, submitForm, isSubmitting } = useFormikContext<
      Record<string, any>
    >()

    const canSubmit = () => {
      // no attribute has been selected yet
      if (!values["customAttribute"]) return false

      // the product has SKUs but we haven't chosen a default option
      if (
        skusPresent &&
        !get(values, ["defaultCustomAttributeOption"]) &&
        !get(values, ["defaultCustomAttributeOptionValue"])
      ) {
        return false
      }

      return true
    }

    return (
      <CanopyButton
        size="small"
        onClick={submitForm}
        variant="primary"
        disabled={!canSubmit()}
        loading={isSubmitting}
      >
        Add Attribute to Product
      </CanopyButton>
    )
  }

  return (
    <div>
      <CanopyButton size="small" variant="primary" onClick={handleClickOpen}>
        Add Attribute
      </CanopyButton>

      <CmsDrawer open={dialogOpen} title="Add Attribute" onClose={handleClose}>
        <Formik
          initialValues={{
            customAttribute: null,
            defaultCustomAttributeOption: null,
            defaultCustomAttributeOptionPackagingLabelId: null,
            defaultCustomAttributeOptionUnitId: null,
            defaultCustomAttributeOptionValue: null,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, resetForm }) => (
            <>
              {availableAttributesIsFetching && <Loader />}

              {!availableAttributesIsFetching && (
                <>
                  <CustomAttributeAutocomplete />

                  {values["customAttribute"] &&
                    !get(values, ["customAttribute", "freeSolo"]) && (
                      <AttributeDetailFields />
                    )}
                </>
              )}
              <CanopyFlex className="canopy-mbs-6x" alignItems="flex-start">
                <SubmitButton />

                <CanopyButton
                  className="canopy-mis-6x"
                  size="small"
                  onClick={() => {
                    resetForm()
                    handleClose()
                  }}
                  variant="tertiary"
                  disabled={isSubmitting}
                >
                  Cancel
                </CanopyButton>
              </CanopyFlex>
            </>
          )}
        </Formik>
      </CmsDrawer>
    </div>
  )
}
export default AddProductAttributeForm
