import { useCallback, useEffect, useState } from "react"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"
import { debounce } from "lodash"

/**
 *
 * Type for parameters to be passed into the custom useComboboxSearch hook.
 *
 * @template T The type of item expected from the `fetchMethod` return array
 *
 */
export type ComboboxSearchParams<T> = {
  /**
   * A data fetching function to be called on combobox input change.
   * @param query The search string entered into a combobox input.
   * @returns A Promise resolving in an array of fetched items.
   */
  fetchMethod: (query?: string, ...args) => Promise<T[]>

  /**
   * The key for the field on each fetched item to be converted to an option label.
   **/
  optionLabelKey?: string

  /**
   * The key for the field on each fetched item to be converted to an option value.
   **/
  optionValueKey?: string

  /**
   * @param item The fetched item to be converted to an option item.
   * @returns A formatted OptionItem
   * @description A getter function to format an option item from a fetched item.
   *
   * Best used when:
   *
   * - The option label or value will come from a nested field on the fetched item
   * - Or when additional fields need to be added to an option
   */
  optionGetter?: (item: T) => OptionItem
}

/**
 *
 * A custom React hook that fetches data based on a search query, manages loading state, and converts fetched items into valid combobox options.
 * @template T The type of item expected to be fetched
 * @param searchParams An object containing a data fetching function, a key from each fetched item for label conversion, and a key for value conversion.
 * @param debounceDelay The number of milliseconds desired after a key input before calling the fetch function.
 *
 * @returns An object containing a list of options, a loading state, and a search function.
 *
 */
export function useComboboxSearch<T>(
  searchParams: ComboboxSearchParams<T>,
  debounceDelay = 500
) {
  const {
    fetchMethod,
    optionLabelKey = "name",
    optionValueKey = "id",
    optionGetter,
  } = searchParams

  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<OptionItem[]>([])

  const normalizeOptions = (items: T[] = []): OptionItem[] => {
    return items.map((item) => {
      if (!!optionGetter) {
        return optionGetter(item)
      } else {
        return {
          label: item[optionLabelKey]?.toString() || "",
          value: item[optionValueKey]?.toString() || "",
        }
      }
    })
  }

  const handleSearch = async (query: string = "", ...args) => {
    if (!query) {
      return setOptions([])
    }

    setLoading(true)

    const items: T[] = await fetchMethod(query, ...args)
    const normalizedOptions: OptionItem[] = normalizeOptions(items)

    setOptions(normalizedOptions)
    setLoading(false)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSearch = useCallback(
    debounce(handleSearch, debounceDelay),
    [debounceDelay]
  )

  useEffect(() => {
    // cancel any pending async calls during teardown
    return () => debouncedHandleSearch.cancel()
  }, [debouncedHandleSearch])

  return { options, loading, handleSearch: debouncedHandleSearch }
}
