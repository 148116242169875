import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import SalesCollaborationToolsNetwork from "./Network"
import { withBrowserRouter } from "routers/BrowserRouter"
import { employerPrefix } from "utilities/url"
import SalesCollaborationToolsLowEngagement from "./LowEngagement"
import SalesCollaborationToolsSaved from "./Saved"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"
import { FavoriteFacilitiesProvider } from "./context/FavoriteFacilitiesContext"

export interface Props {
  favoriteFacilitiesCount: number
  lowEngagementCount: number
  supplierOrgExternalId: string
  salesTeamCollaborationToolsSavedMainApp?: boolean
  networkExperienceLowEngagement?: boolean
  isSsr: boolean
}

export const BASE_NETWORKS_PATH = "/networks"

export const SalesCollaborationToolsScreens = (props: Props) => {
  return (
    <FavoriteFacilitiesProvider initialCount={props.favoriteFacilitiesCount}>
      <Switch>
        <Route
          exact
          path={BASE_NETWORKS_PATH}
          render={() => <SalesCollaborationToolsNetwork {...props} />}
        />
        <Route
          exact
          path={`${BASE_NETWORKS_PATH}/${Option.Saved.toLocaleLowerCase()}`}
          render={() => <SalesCollaborationToolsSaved {...props} />}
        />
        <Route
          exact
          path={`${BASE_NETWORKS_PATH}/${Option.LowEngagement.toLocaleLowerCase()}`}
          render={() => <SalesCollaborationToolsLowEngagement {...props} />}
        />
        <Route path="/">
          <Redirect to={BASE_NETWORKS_PATH} />
        </Route>
      </Switch>
    </FavoriteFacilitiesProvider>
  )
}

export default withBrowserRouter(SalesCollaborationToolsScreens, {
  basename: employerPrefix(),
})
