import { useQuery, useQueryClient } from "@tanstack/react-query"

export const useGetSelectedDiagnoses = (pendingOrderId?: string) => {
  const queryClient = useQueryClient()

  return useQuery({
    queryKey: ["diagnoses", "selected", pendingOrderId],
    queryFn: () => {
      // TODO: Replace with an actual API call in https://parachutehealth.atlassian.net/browse/WF-29980
      const data = queryClient.getQueryData<{ diagnoses: string[] }>([
        "diagnoses",
        "selected",
        pendingOrderId,
      ])
      return data || { diagnoses: [] }
    },
    enabled: !!pendingOrderId,
  })
}
