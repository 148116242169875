import React, { useState } from "react"
import { CanopyButton, CanopyButtonProps } from "@parachutehealth/canopy-button"

interface Props extends CanopyButtonProps<"button"> {
  createDmeOrder(): Promise<void>
  label: string
}

function CreateOrderButton(props: Props) {
  const { createDmeOrder, label, ...rest } = props

  const [disabled, setDisabled] = useState(false)

  const onClick = () => {
    setDisabled(true)
    createDmeOrder()
  }

  return (
    <CanopyButton onClick={onClick} disabled={disabled} {...rest}>
      {label}
    </CanopyButton>
  )
}

CreateOrderButton.defaultProps = {
  label: "Create new order",
}

export default CreateOrderButton
