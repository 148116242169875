import {
  DmeOrderWarning,
  Employer,
  EmployerType,
  RejectedSupplierWarningMetadata,
  SignatureWarningMetadata,
  ManagerApprovalWarningMetadata,
} from "sharedTypes"

const rejectedSupplierText = (warning: DmeOrderWarning) => {
  const { supplierName } = warning.metadata as RejectedSupplierWarningMetadata

  return `${supplierName} cannot fulfill this order. Please update order or select a new supplier.`
}

const signatureRequestMethodText = (currentEmployer: Employer) => {
  let text = "Incomplete signature request method"
  if (currentEmployer.employerType === EmployerType.Supplier) {
    text += " (facility to complete)"
  }
  return text
}

const signatureReadyDocumentationText = (currentEmployer: Employer): string => {
  const text =
    currentEmployer.employerType === EmployerType.Supplier
      ? "Incomplete documentation (facility to complete)"
      : "Incomplete or missing documentation"

  return text
}

const supplierSignatureText = (warning: DmeOrderWarning): string => {
  const { type } = warning.metadata as SignatureWarningMetadata

  switch (type) {
    case "pending":
      return "Signature pending"
    case "manual":
      return "Please review uploaded documentation."
    case "needed":
      return "This order has not been signed."
    case "invalid":
      return "This order has been changed since it was last signed. A new signature is required."
    default:
      return "Please review as there may be problems with the signature."
  }
}

const managerApprovalText = (warning: DmeOrderWarning): string => {
  const { packageNames } = warning.metadata as ManagerApprovalWarningMetadata

  return `The following packages require manager approval: ${packageNames.join(
    ", "
  )}. Add a manager as a follower on this order to request approval.`
}

const WARNING_KEY_TO_TEXT_MAPPING = {
  already_being_accepted: "Acceptance already in progress",
  billing_address: "Incomplete billing address",
  billing_phone_number: "Incomplete patient phone",
  carrier_authorization_number: "Incomplete pre-authorization",
  delivery_phone_number: "Incomplete delivery phone",
  diagnoses: "Incomplete diagnoses",
  discharge_date: "Incomplete discharge date",
  medicaid_form_review_recommended:
    "Review documentation and edit medicaid forms as necessary. Making edits will require the order to be re-signed.",
  medicare_signing_ability_unknown: "Medicare signing ability unknown",
  not_addable_package_configuration: "Some products may no longer be offered.",
  ordering_doctor: "Incomplete clinician",
  pecos_certification:
    "Can't create a signature request for a clinician who is not pecos certified",
  prevent_supplier_edit:
    "This order cannot be edited. Push order to facility to request edits.",
  primary_insurance_policy: "Incomplete primary insurance",
  product_requirements: "Incomplete product requirements",
  products: "Incomplete products",
  proof_of_delivery:
    "Don't forget to submit a Proof of Delivery signed by the patient/designee to the supplier to complete this order.",
  rx_details: "Incomplete prescription details",
  rx_quantity: "Incomplete dispense quantity",
  secondary_insurance_policy: "Incomplete secondary insurance",
  signature_ready_questionnaire: "Incomplete medical necessity criteria",
  supplier_system_clinical_facility_id:
    "External System ID Needed. Assign an ID to this facility in order to accept the order.",
  tertiary_insurance_policy: "Incomplete tertiary insurance",
}

export const getWarningText = (
  warning: DmeOrderWarning,
  currentEmployer: Employer
) => {
  switch (warning.key) {
    case "rejected_supplier":
      return rejectedSupplierText(warning)
    case "signature_request_method":
      return signatureRequestMethodText(currentEmployer)
    case "signature_ready_documentation":
      return signatureReadyDocumentationText(currentEmployer)
    case "supplier_signature":
      return supplierSignatureText(warning)
    case "manager_approval":
      return managerApprovalText(warning)
    default:
      return WARNING_KEY_TO_TEXT_MAPPING[warning.key]
  }
}
