import React, { useMemo, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { therapySelectionPath, medicalNecessityPath } from "../routes"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import OptionalDiagnosesList from "./OptionalDiagnosesList"
import RelevantDiagnosesList from "./RelevantDiagnosesList"
import { unique } from "utilities/array"
import { Layout } from "../Layout/Layout"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import SaveStatusIndicator from "../SaveStatus/SaveStatusIndicator"
import { getSaveStatusIndicatorState } from "./utils"
import { useGetRelevantDiagnoses } from "../api/queries/useGetRelevantDiagnoses/useGetRelevantDiagnoses"
import { useSaveDiagnoses } from "../api/mutations/useSaveDiagnoses/useSaveDiagnoses"
import { useGetSelectedDiagnoses } from "../api/queries/useGetSelectedDiagnoses/useGetSelectedDiagnoses"
import { partition } from "lodash"

interface SelectedDiagnoses {
  relevant: string[]
  optional: string[]
}

const Diagnoses: React.FC = () => {
  const { pendingOrderId, therapySelectionId } = useParams()
  const history = useHistory()

  const {
    data: { diagnoses: relevantDiagnoses = [] } = { relevantDiagnoses: [] },
  } = useGetRelevantDiagnoses(pendingOrderId)
  const {
    data: { diagnoses: allSelectedDiagnoses = [] } = { selectedDiagnoses: [] },
  } = useGetSelectedDiagnoses(pendingOrderId)
  const {
    mutateAsync: saveDiagnoses,
    isLoading: saveInProgress,
    isIdle,
    isError,
  } = useSaveDiagnoses(pendingOrderId)

  const partitionedSelectedDiagnoses = useMemo(() => {
    const [relevant, optional] = partition(allSelectedDiagnoses, (diagnosis) =>
      relevantDiagnoses.map((d) => d.code).includes(diagnosis)
    )
    return { relevant, optional }
  }, [allSelectedDiagnoses, relevantDiagnoses])

  const [validationState, setValidationState] = useState({
    errorMessage: "",
    warningMessage: "",
  })

  const hasRelevantDiagnoses = useMemo(() => relevantDiagnoses.length > 0, [
    relevantDiagnoses,
  ])

  const handleContinue: React.MouseEventHandler = (e) => {
    if (
      partitionedSelectedDiagnoses.optional.length ||
      partitionedSelectedDiagnoses.relevant.length
    ) {
      history.push(medicalNecessityPath(pendingOrderId, therapySelectionId))
    } else {
      e.preventDefault()
      setValidationState((prev) => ({
        ...prev,
        errorMessage: "Select or add at least one diagnosis to continue",
      }))
    }
  }

  const saveCombinedDiagnosesList = async (
    newDiagnosesList: Partial<SelectedDiagnoses>
  ) => {
    const combined: SelectedDiagnoses = {
      ...partitionedSelectedDiagnoses,
      ...newDiagnosesList,
    }
    const hasAnyDiagnoses =
      combined.relevant.length > 0 || combined.optional.length > 0

    try {
      // React batches state updates, so we can't rely on state being updated here. Instead, we use the combined list that we just created
      // React reference: https://react.dev/reference/react/useState#ive-updated-the-state-but-logging-gives-me-the-old-value
      const response = await saveDiagnoses({
        diagnoses: unique([...combined.relevant, ...combined.optional]),
      })
      const therapy = response.data[0]

      setValidationState((prev) => ({
        errorMessage: hasAnyDiagnoses ? "" : prev.errorMessage,
        warningMessage:
          therapy?.warnings.length > 0 && hasAnyDiagnoses
            ? `Additional or different diagnoses are needed for ${therapy.name}`
            : "",
      }))
    } catch (error) {}
  }

  const handleRelevantDiagnosesChange = (
    selectedRelevantDiagnoses: string[]
  ) => {
    if (selectedRelevantDiagnoses.length > 0) {
      setValidationState((prev) => ({
        ...prev,
        errorMessage: "",
      }))
    }
    saveCombinedDiagnosesList({ relevant: selectedRelevantDiagnoses })
  }

  const handleOptionalDiagnosesChange = (selectedOptionalDiagnoses: string[]) =>
    saveCombinedDiagnosesList({ optional: selectedOptionalDiagnoses })

  const renderValidationMessages = () => {
    if (validationState.errorMessage) {
      return (
        <CanopyNotice variant="error" title={validationState.errorMessage} />
      )
    } else if (validationState.warningMessage) {
      return (
        <CanopyNotice
          variant="warning"
          title={validationState.warningMessage}
        />
      )
    }
    return null
  }

  return (
    <Layout>
      <Layout.Heading
        backlinkPath={therapySelectionPath(pendingOrderId, therapySelectionId)}
        backlinkText="Back to therapy"
        headingText="Diagnosis"
        showRequiredFieldKey={true}
      />
      <Layout.Body>
        {hasRelevantDiagnoses && (
          <RelevantDiagnosesList
            onDiagnosesListChange={handleRelevantDiagnosesChange}
            selectedDiagnoses={partitionedSelectedDiagnoses.relevant}
          />
        )}
        <OptionalDiagnosesList
          isRequired={!hasRelevantDiagnoses}
          onDiagnosesListChange={handleOptionalDiagnosesChange}
          selectedDiagnoses={partitionedSelectedDiagnoses.optional}
        />
        {renderValidationMessages()}
        <CanopyFlex direction="row" alignItems="center" gap="12X">
          <CanopyButton
            as={Link}
            to={medicalNecessityPath(pendingOrderId, therapySelectionId)}
            onClick={handleContinue}
            disabled={saveInProgress}
          >
            Continue to Medical Necessity
          </CanopyButton>
          {!isIdle && (
            <SaveStatusIndicator
              saveState={getSaveStatusIndicatorState(saveInProgress, isError)}
            />
          )}
        </CanopyFlex>
      </Layout.Body>
    </Layout>
  )
}

export default Diagnoses
