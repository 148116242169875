import { get } from "services/api"
import { UseGetRelevantDiagnosesResponse } from "./types"
import { useQuery } from "@tanstack/react-query"
import { relevantDiagnosesUrl } from "applications/DoctorPendingOrderWorkflow/urls"
import { ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"

export const useGetRelevantDiagnoses = (pendingOrderId: string) => {
  return useQuery({
    queryKey: ["diagnoses", "relevant", pendingOrderId],
    queryFn: async (): Promise<UseGetRelevantDiagnosesResponse> => {
      try {
        const response = await get(relevantDiagnosesUrl(pendingOrderId))
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return { diagnoses: [] }
      }
    },
  })
}
