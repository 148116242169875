import React from "react"
import { useMutation, UseMutationResult } from "@tanstack/react-query"

import { ResourceUpdateResponse } from "./api"
import { useCsrInboxState } from "../../csrInboxStateContext"
import { OptionItem } from "@parachutehealth/canopy-combobox-field"

export function isSuccess<G extends string>(
  response: ResourceUpdateResponse<G>
): response is { success: boolean } {
  return "success" in response
}

export function onBlurHelper<TypeOfOurFieldName extends string>(
  fieldName: TypeOfOurFieldName,
  value: string | undefined,
  mutateFunction: UseMutationResult<
    ResourceUpdateResponse<TypeOfOurFieldName>,
    unknown,
    string,
    unknown
  >,
  setError: (error: string | undefined) => void,
  setValue: (value: string) => void
) {
  const onBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value !== value) {
      mutateFunction.mutateAsync(e.target.value).then((response) => {
        if (isSuccess(response)) {
          setError(undefined)
        } else {
          const errors = response.errors
          const error = errors[fieldName]
          setError(error ? error[0] : "Something went wrong")
        }
      })
      setValue(e.target.value)
    }
  }
  return onBlur
}

export function isOptionItem(
  newOption: OptionItem | OptionItem[]
): newOption is OptionItem {
  return (newOption as OptionItem)?.value !== undefined
}

export function useGenericUpdate<TypeOfOurFieldName extends string>(
  fieldName: TypeOfOurFieldName,
  updateFunction: (
    supplierOrganizationId: string,
    csrInboxStateId: string,
    partialRecord: { [key in TypeOfOurFieldName]: string }
  ) => Promise<ResourceUpdateResponse<TypeOfOurFieldName>>,
  mutationKey: string[]
) {
  const csrInboxState = useCsrInboxState()
  return useMutation({
    mutationFn: (fieldValue: string) =>
      updateFunction(csrInboxState.supplierOrganization.id, csrInboxState.id, {
        [fieldName]: fieldValue,
      } as { [key in TypeOfOurFieldName]: string }),
    mutationKey: mutationKey,
  })
}

export function isComboBoxOptionItem(
  newOption: OptionItem | OptionItem[]
): newOption is OptionItem {
  return (newOption as OptionItem)?.value !== undefined
}
