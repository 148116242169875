import { useQueryClient, useMutation } from "@tanstack/react-query"
import { WorkflowStep } from "applications/DoctorPendingOrderWorkflow/sharedTypes"
import { pendingOrdersSaveQuestionAnswerUrl } from "applications/DoctorPendingOrderWorkflow/urls"
import { put } from "services/api"
import { ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"
import { surveyQuestionsKey } from "../../utils"
import { UseSaveAnswerPayload } from "./types"

export const useSaveAnswer = (
  pendingOrderId: string,
  therapySelectionId: string,
  workflowStep: WorkflowStep
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ answerType, answerValue, question }: UseSaveAnswerPayload) =>
      put(
        pendingOrdersSaveQuestionAnswerUrl(pendingOrderId, question.questionId),
        {
          answerType,
          answerValue,
          surveyId: question.surveyId,
          workflowStep,
        }
      ),
    onSuccess: async (response) => {
      queryClient.setQueryData(
        surveyQuestionsKey(workflowStep, pendingOrderId, therapySelectionId),
        response.data
      )
      return response.data
    },
    onError: (error) => {
      handleError(error as ApplicationError)
    },
  })
}
