import { get, put } from "services/api"
import {
  rxSurveyQuestionAnswerUrl,
  workflowLineItemGroupPreferenceUrl,
  updateEmploymentPreferencesUrl,
} from "applications/Workflow/urls"
import {
  RxDetails,
  SurveyQuestion,
  SurveyAnswerValue,
  SurveyAnswerType,
} from "sharedTypes"

export function fetchInitialPackageConfigurationData() {
  return get(workflowLineItemGroupPreferenceUrl()).then(({ data }) => data)
}

export function answerRxSurveyQuestion(
  packageConfigurationId: string,
  question: SurveyQuestion,
  answerType: SurveyAnswerType,
  answerValue: SurveyAnswerValue
): Promise<RxDetails> {
  return put(rxSurveyQuestionAnswerUrl(question.questionId), {
    answerType,
    answerValue,
    packageConfigurationId,
    surveyId: question.surveyId,
  }).then((response) => response.data)
}

export const updateEmploymentPreferences = (params) =>
  put(updateEmploymentPreferencesUrl(), params)
