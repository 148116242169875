import { get, put, post, del } from "services/api"
import {
  searchSkusUrl,
  updateCartItemQuantityUrl,
  createCartItemUrl,
  removeCartItemUrl,
} from "../../../urls"
import { ResourceUpdateResponse } from "../api"

export type Sku = {
  id: string
  description: string
  hcpcs: string
}

export type CartItem = {
  id: string
  skuId: string
  quantity: number
  description: string
}

export const searchSkus = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  searchTerm: string
): Promise<Sku[]> => {
  return get(searchSkusUrl(supplierOrganizationId, csrInboxStateId), {
    searchTerm: searchTerm,
  })
    .then((res) => {
      return (
        res.data?.map((sku) => ({
          id: sku.id,
          description: sku.description,
          hcpcs: sku.hcpcs,
        })) || []
      )
    })
    .catch(() => [])
}

export const updateCartItemQuantity = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  cartItemId: string,
  quantity: number
): Promise<ResourceUpdateResponse<keyof CartItem>> => {
  return put(
    updateCartItemQuantityUrl(
      supplierOrganizationId,
      csrInboxStateId,
      cartItemId
    ),
    { quantity }
  )
    .then(() => ({ success: true }))
    .catch((error) => ({
      errors: error.response?.data?.errors || {
        quantity: ["Failed to update quantity"],
      },
    }))
}

type CreateCartItemResponse =
  | {
      success: true
      cartItem: CartItem
    }
  | { success: false; errors: Record<string, string[]> }

export const createCartItem = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  skuId: string
): Promise<CreateCartItemResponse> => {
  return post(createCartItemUrl(supplierOrganizationId, csrInboxStateId), {
    cart_item: { skuId: skuId },
  })
    .then((response) => response.data)
    .catch((error) => ({
      errors: error.response?.data?.errors || {
        base: ["Failed to create cart item"],
      },
    }))
}

export const removeCartItem = async (
  supplierOrganizationId: string,
  csrInboxStateId: string,
  cartItemId: string
): Promise<ResourceUpdateResponse<string>> => {
  return del(
    removeCartItemUrl(supplierOrganizationId, csrInboxStateId, cartItemId)
  )
    .then(() => ({ success: true }))
    .catch((error) => ({
      errors: error.response?.data?.errors || {
        base: ["Failed to remove cart item"],
      },
    }))
}
