import formatDate from "date-fns/format"
import addDays from "date-fns/add_days"
import subDays from "date-fns/sub_days"
import parse from "date-fns/parse"
import isValid from "date-fns/is_valid"
import diffInSeconds from "date-fns/difference_in_seconds"
import compareAsc from "date-fns/compare_asc"

import isFuture from "date-fns/is_future"
import isPast from "date-fns/is_past"
import isToday from "date-fns/is_today"

export const SHORT_DATE_FORMAT = "M/DD"
export const DATE_FORMAT = "MM/DD/YYYY"
export const SERVER_DATE_FORMAT = "YYYY-MM-DD"
export const EVENT_DATE_TIME_FORMAT = "MM/DD/YYYY [at] h:mmA"
export const EVENT_TIME_DATE_FORMAT = "h:mmA [on] MM/DD/YYYY"
export const DATE_TIME_FORMAT = "MM/DD/YYYY h:mmA"
export const DATE_VALIDATION_MESSAGE = "Invalid date"
export const BUTTON_DATE_FORMAT = "MM/DD"

export function getTimestamp() {
  return new Date().getTime()
}

export function getISOTimestamp() {
  return new Date().toISOString()
}

export function yesterday() {
  return formatDate(subDays(new Date(), 1), DATE_FORMAT)
}

export function today() {
  return formatDate(new Date(), DATE_FORMAT)
}

export function tomorrow() {
  return formatDate(addDays(new Date(), 1), DATE_FORMAT)
}

export function format(date, format?) {
  return formatDate(date, format || DATE_FORMAT)
}

export function convert(date, format) {
  return formatDate(parse(date), format)
}

export function isDateValid(date) {
  return date && isValid(parse(date))
}

export function isDateInFuture(date) {
  return date && isFuture(parse(date))
}

export function isDateInPast(date) {
  return date && isPast(parse(date)) && !isToday(parse(date))
}

export function isDateAfter(date1, date2) {
  return date1 && date2 && compareAsc(parse(date1), parse(date2)) > 0
}

const SECONDS_IN_A_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_A_MINUTE * 60
const SECONDS_IN_A_DAY = SECONDS_IN_HOUR * 24
const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * 30
const SECONDS_IN_A_YEAR = SECONDS_IN_A_MONTH * 12

export function differenceInSeconds(
  dateLeft: Date | string | number,
  dateRight: Date | string | number
): number {
  return diffInSeconds(dateLeft, dateRight)
}

export function timestampInWords(date: string, timeFormat: string, now?: Date) {
  if (!date) return ""

  const from = new Date(date)
  now = now || new Date()
  const secondsBetweenDates = differenceInSeconds(now.getTime(), from.getTime())
  const minutes = Math.round(secondsBetweenDates / SECONDS_IN_A_MINUTE)

  if (secondsBetweenDates < 60) {
    return "just now"
  }

  if (minutes < 60) {
    return `${minutes}m ago`
  }

  return format(date, timeFormat)
}

export function timeAgoInWords(date: string, now?: Date): string {
  if (!date) return ""

  now = now || new Date()

  const from = new Date(date).getTime()
  let distance_in_seconds = Math.round((now.getTime() - from) / 1000)

  const years = Math.floor(distance_in_seconds / SECONDS_IN_A_YEAR)
  distance_in_seconds = distance_in_seconds % SECONDS_IN_A_YEAR

  const months = Math.floor(distance_in_seconds / SECONDS_IN_A_MONTH)
  distance_in_seconds = distance_in_seconds % SECONDS_IN_A_MONTH

  const days = Math.floor(distance_in_seconds / SECONDS_IN_A_DAY)
  distance_in_seconds = distance_in_seconds % SECONDS_IN_A_DAY

  const hours = Math.floor(distance_in_seconds / SECONDS_IN_HOUR)
  distance_in_seconds = distance_in_seconds % SECONDS_IN_HOUR

  const minutes = Math.floor(distance_in_seconds / SECONDS_IN_A_MINUTE)
  const seconds = distance_in_seconds % SECONDS_IN_A_MINUTE

  if (years >= 2) {
    return `over ${years} years ago`
  } else if (years === 1) {
    return `1 year ago`
  } else if (months >= 1) {
    const roundedMonth = days >= 15 ? 1 : 0
    const finalMonths = months + roundedMonth
    return `${finalMonths} month${finalMonths > 1 ? "s" : ""} ago`
  } else if (days >= 1) {
    const roundedDay = hours >= 12 ? 1 : 0
    return `${days + roundedDay}d ago`
  } else if (hours >= 1) {
    const roundedHour = minutes >= 30 ? 1 : 0
    return `${hours + roundedHour}h ago`
  } else if (minutes >= 1) {
    const roundedMinute = seconds >= 30 ? 1 : 0
    return `${minutes + roundedMinute}m ago`
  }
  return "1m ago"
}
