import React from "react"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { ClinicalFacilityProfile } from "../index"
import { Field, Formik } from "formik"
import { updateFacilityName } from "../api"
import * as styles from "../index.module.scss"
import ClinicalFacilityProfileProgress from "./ClinicalFacilityProfileProgress"

type Props = {
  clinicalFacility: ClinicalFacilityProfile
  onUpdate: (ClinicalFacilityProfile) => void
  next: () => void
  totalSteps: number
}

const ClinicalFacilityProfileName = (props: Props) => {
  const { clinicalFacility, onUpdate, next, totalSteps } = props

  const submitForm = async (values, setErrors) => {
    try {
      const response = await updateFacilityName(values.name)

      onUpdate(response)
      next()
    } catch (errors: any) {
      setErrors({ name: errors.name })
    }
  }

  const validate = (values) => {
    const errors = {}

    if (!values.name) {
      errors["name"] = "can't be blank"
    }
    return errors
  }

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <ClinicalFacilityProfileProgress totalSteps={totalSteps} step={1} />
      <Formik
        onSubmit={(values, { setErrors }) => submitForm(values, setErrors)}
        initialValues={{ name: clinicalFacility.name }}
        validate={validate}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleSubmit, isSubmitting, errors }) => {
          return (
            <CanopyForm
              className={styles.formContainer}
              onSubmit={handleSubmit}
              data-testid="facility-name-form"
            >
              <h1 className={styles.header}>
                Let's confirm your facility name
              </h1>
              <p>
                This is how suppliers will recognize your facility and send
                orders directly.
              </p>
              <Field
                as={CanopyTextInputField}
                name="name"
                label="Facility name"
                feedbackMessage={errors.name}
              />
              <CanopyButton loading={isSubmitting} type="submit">
                Continue
              </CanopyButton>
            </CanopyForm>
          )
        }}
      </Formik>
    </div>
  )
}

export default ClinicalFacilityProfileName
