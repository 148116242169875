import { clinicalPath } from "../../routes"
import { RouteComponentProps } from "react-router"

export const LEGAL_WARNING =
  "By continuing, I acknowledge that it is my responsibility to ensure that any clinical information inputted by me is true, accurate and consistent with information contained elsewhere in the medical record for the patient. Parachute does not provide recommendations related to the medical necessity or appropriateness of any order or plan of care."

export const CRITERIA_NOT_MET =
  "Based on the provided information, evidence may not support the medical necessity of the product or service. You may still submit the order for further review by the payor. To support your submission, please upload any additional documentation that may assist in the medical review process."

export const CRITERIA_MET =
  "Evidence supports the medical necessity of the product or service. Please select the signing clinician and ensure all details are accurate before final submission."

export const SUPPLIER_CRITERIA_MET =
  "Evidence supports the medical necessity of the product or service. Once the order is accepted, complete the health plan authorization request. The answers and documentation provided here will be included authorization request to determine if the authorization is approved."

export const SUPPLIER_CRITERIA_NOT_MET =
  "Based on the provided information, evidence may not support the medical necessity of the product or service. You can still accept the order and request authorization for further review by the health plan. To support your submission, please upload any additional documentation that may assist in the medical review process."

export const SUPPLIER_REVIEW_COPY =
  "The facility has answered to the best of their ability but the form may not be complete. Making edits will require a new signature."

export const SUPPLIER_REVIEW_CTA = "Please review and edit as needed"

export const FACILITY_REVIEW_CTA = "Answer to the best of your ability"

export const FACILITY_REVIEW_COPY =
  "Please ensure the required fields are filled out and accurate before marking this payor required form as completed. Suppliers will be able to edit any incomplete fields once the order is sent, which will require a new signature."

export type FillablePdfPathRouteProps = RouteComponentProps<{
  documentationRequirementExternalId
}>
export const fillablePdfPathMatcher = (): string =>
  `${clinicalPath()}/pdfs/:documentationRequirementExternalId?`
export const fillablePdfPath = (
  documentationRequirementExternalId?: string
): string => `${clinicalPath()}/pdfs/${documentationRequirementExternalId}`
