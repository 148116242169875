import React, { useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import {
  excludeRelevantDiagnoses,
  formatIcd10CodeOption,
  groupByCategory,
} from "./utils"
import debounce from "awesome-debounce-promise"
import { useGetRelevantDiagnoses } from "../api/queries/useGetRelevantDiagnoses/useGetRelevantDiagnoses"
import { useGetIcd10Codes } from "../api/queries/useGetIcd10Codes/useGetIcd10Codes"

type Props = {
  isRequired: boolean
  onDiagnosesListChange: (selectedDiagnoses: string[]) => void
  selectedDiagnoses: string[]
}

const OptionalDiagnosesList: React.FC<Props> = ({
  isRequired,
  onDiagnosesListChange,
  selectedDiagnoses,
}) => {
  const { pendingOrderId } = useParams()

  const [searchTerm, setSearchTerm] = useState("")

  const debouncedSetSearchTerm = debounce(setSearchTerm, 250)
  const {
    data: { codes: icd10Codes = [] } = { icd10Codes: [] },
    isLoading,
  } = useGetIcd10Codes(pendingOrderId, searchTerm)
  const {
    data: { diagnoses: relevantDiagnoses = [] } = { relevantDiagnoses: [] },
  } = useGetRelevantDiagnoses(pendingOrderId)

  const optionalDiagnoses = useMemo(() => {
    return excludeRelevantDiagnoses(icd10Codes, relevantDiagnoses)
  }, [icd10Codes, relevantDiagnoses])

  const formattedSelectedDiagnoses = useMemo(() => {
    return optionalDiagnoses
      .filter((diagnosis) => selectedDiagnoses.includes(diagnosis.code))
      .map(formatIcd10CodeOption)
  }, [optionalDiagnoses, selectedDiagnoses])

  const formattedAndGroupedIcd10Codes = useMemo(() => {
    return groupByCategory(optionalDiagnoses)
  }, [optionalDiagnoses])

  const searchForDiagnoses = async (value: string) => {
    debouncedSetSearchTerm(value)
  }

  let currentDiagnosesList: OptionItem | OptionItem[] | null = null
  const handleChange = (newValue: OptionItem | OptionItem[] | null) => {
    // This is a bizarre hack to prevent onChange from being called twice.
    // We ought to remove this once the CanopyComboboxField component is fixed;
    // see https://parachutehealth.atlassian.net/browse/DS-2737
    if (currentDiagnosesList === newValue) return
    currentDiagnosesList = newValue
    // </hack>

    const selectedDiagnoses = ((newValue as OptionItem[]) || []).map(
      (item) => item.value
    )
    onDiagnosesListChange(selectedDiagnoses)
  }

  return (
    <CanopyComboboxField
      label="Add more diagnoses"
      multiple
      required={isRequired}
      optional={!isRequired}
      options={formattedAndGroupedIcd10Codes}
      onInputChange={searchForDiagnoses}
      loading={isLoading}
      loadingText="Searching for diagnoses"
      onChange={handleChange}
      value={formattedSelectedDiagnoses}
    />
  )
}

export default OptionalDiagnosesList
