import { useQuery } from "@tanstack/react-query"
import { pendingOrdersSearchIcd10CodesUrl } from "applications/DoctorPendingOrderWorkflow/urls"
import { ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"
import { get } from "services/api"
import { UseGetIcd10CodesResponse } from "./types"

export const useGetIcd10Codes = (
  pendingOrderId: string,
  searchTerm: string
) => {
  return useQuery({
    queryKey: ["diagnosis", "icd10Codes", pendingOrderId, searchTerm],
    queryFn: async (): Promise<UseGetIcd10CodesResponse> => {
      try {
        const response = await get(
          pendingOrdersSearchIcd10CodesUrl(pendingOrderId),
          {
            search: searchTerm,
          }
        )
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return { codes: [] }
      }
    },
  })
}
