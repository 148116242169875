import React, { FC } from "react"
import { tokenize } from "../../../../../utilities/commentLexer"
import * as styles from "./ActionableOrderCard.module.scss"
import { DashboardEvent } from "../../../sharedTypes"
import classNames from "classnames"
import { useFeatureFlags } from "components/FeatureFlagContext"

interface Props {
  lastComment: DashboardEvent
}

interface Token {
  type: string
  value: string
}

const CommentWithMentions: FC<Props> = ({ lastComment }) => {
  const tokens: Token[] = tokenize(
    lastComment.rawContent || lastComment.content
  )
  const { isFeatureEnabled } = useFeatureFlags()
  const isDashboardCacheFeatureEnabled = isFeatureEnabled(
    "facilityDashboardUseCache"
  )

  return (
    <>
      {tokens.map((node: Token, index: number) => {
        const key = node.type + index
        if (node.type === "mention") {
          const splitIndex = node.value.lastIndexOf("\n")
          const mentionName = node.value.substring(0, splitIndex)
          const mentionGid = node.value.substring(splitIndex + 1)
          const mentionMetadata = lastComment.employmentMentions.find(
            ({ id }) => id === mentionGid
          )

          let isCurrentEmploymentMention: boolean
          if (isDashboardCacheFeatureEnabled) {
            isCurrentEmploymentMention =
              mentionMetadata?.employerId === window.parachute?.employerId &&
              mentionMetadata?.userId === window.parachute?.currentUserId
          } else {
            isCurrentEmploymentMention = Boolean(
              mentionMetadata?.isCurrentEmployment
            )
          }

          return (
            <span
              className={classNames(
                styles.lastCommentBody,
                styles.mention,
                isCurrentEmploymentMention && styles.currentEmploymentMention
              )}
              key={key}
            >
              @{mentionName}
            </span>
          )
        }
        return (
          <span aria-hidden="true" className={styles.lastCommentBody} key={key}>
            {node.value}
          </span>
        )
      })}
    </>
  )
}

export default CommentWithMentions
