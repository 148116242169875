import React from "react"
import { ClinicalFacilityProfile, User } from "../index"
import * as styles from "../index.module.scss"
import InviteUserForm from "./InviteUserForm"
import { useFormikParachute } from "applications/shared/hooks/useFormikParachute/useFormikParachute"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyLink } from "@parachutehealth/canopy-link"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyForm } from "@parachutehealth/canopy-form"
import { inviteUsers } from "../api"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import ClinicalFacilityProfileProgress from "./ClinicalFacilityProfileProgress"
import UsersTable from "./UsersTable"

type Props = {
  clinicalFacility?: ClinicalFacilityProfile
  onUpdate?: () => Promise<any>
  users: User[]
  next: () => void
  back: () => void
  totalSteps: number
}

const initialUser = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
}

interface UserFormValues {
  users: {
    firstName: string
    lastName: string
    role: string
    email: string
  }[]
}

const ClinicalFacilityProfileInviteUsers = (props: Props) => {
  const { back, next, users, totalSteps } = props

  const onSubmit = async (values: UserFormValues) => {
    try {
      await inviteUsers(values.users)
      next()
    } catch (errors: any) {
      formik.setErrors(errors)
    }
  }

  const formik = useFormikParachute({
    initialValues: {
      users: [initialUser],
    },
    onSubmit: (values) => {
      return onSubmit(values)
    },
  })

  const updateUser = (user, index) => {
    formik.setValues(
      {
        users: formik.values.users.map((u, i) => (i === index ? user : u)),
      },
      false
    )
  }

  const addUserInput = (e) => {
    e.preventDefault()

    formik.setValues({ users: [...formik.values.users, initialUser] }, false)
  }

  const removeUserInput = (index) => {
    formik.setValues(
      {
        users: formik.values.users.filter((_, i) => i !== index),
      },
      false
    )
  }

  return (
    <div className={styles.profileWorkflowStepContainer}>
      <ClinicalFacilityProfileProgress totalSteps={totalSteps} step={5} />
      <div
        className={styles.formContainer}
        data-testid="facility-invite-users-form"
      >
        <h1 className={styles.header}>
          Invite people to join you on Parachute
        </h1>
        <p>
          Parachute Health is better with your team. Add clinicians and manage
          DME orders together
        </p>

        <UsersTable users={users} />

        <CanopyForm onSubmit={formik.handleSubmit}>
          <div className={styles.inviteUsersInputsContainer}>
            {formik.values.users.map((user, index) => (
              <div key={index} className={styles.inviteUsersInputContainer}>
                <InviteUserForm
                  index={index}
                  user={user}
                  onChange={(user) => updateUser(user, index)}
                  removeUserInput={() => {
                    removeUserInput(index)
                  }}
                  feedbackMessages={formik.errors[index]}
                />
              </div>
            ))}
          </div>

          <div className={styles.addAnotherContainer}>
            {formik.values.users.length <= 15 && (
              <CanopyLink href="#" onClick={addUserInput}>
                + Add another
              </CanopyLink>
            )}
          </div>

          <CanopyFlex
            justifyContent="space-between"
            gap="16X"
            className={styles.inviteUsersButtonsContainer}
          >
            <CanopyButton variant="secondary" onClick={back} type="button">
              Back
            </CanopyButton>
            <CanopyFlex justifyContent="space-between" gap="4X">
              <CanopyButton
                onClick={() => window.location.assign("/")}
                variant="tertiary"
              >
                Skip for now
              </CanopyButton>
              <CanopyButton type="submit" loading={formik.isSubmitting}>
                Invite users
              </CanopyButton>
            </CanopyFlex>
          </CanopyFlex>
        </CanopyForm>
        <CanopyNotice
          className={styles.inviteUsersNotice}
          title="What happens next?"
          message={`1. People get an email invite to join your team.
            2. They can create and manage DME orders.
            3. You can add more members and edit permissions in the admin console.`}
        />
      </div>
    </div>
  )
}

export default ClinicalFacilityProfileInviteUsers
