import React, { useEffect, useState } from "react"
import { makeApi } from "applications/FacilitySelectForm/api"
import { patch } from "services/api"
import { FacilityOption } from "sharedTypes"
import { useCsrInboxState } from "applications/SupplierOrganizationInboxDocument/csrInboxStateContext"
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {
  CanopyComboboxField,
  SelectOption,
} from "@parachutehealth/canopy-combobox-field"
import * as styles from "./index.module.scss"
import * as utilities from "./utilities"
import { isComboBoxOptionItem } from "../helpers"
import { infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually } from "../../../queries"

export type InboxStateClinicalFacility = {
  facility?: {
    sgid: string
    name: string
  }
  extractedName?: string
  extractedAddress: {
    line1: string | undefined
    line2: string | undefined
    city: string | undefined
    state: string | undefined
    zip: string | undefined
  }
}
type UpdateFacilityInformation = (
  updatedData: Partial<InboxStateClinicalFacility>
) => Promise<void>

const facilityQueryKey = ["csrInboxState", "facility"]
export const setFacilityQueryData = (
  client: QueryClient,
  facilityData?: InboxStateClinicalFacility
) => {
  client.setQueryData(facilityQueryKey, facilityData)
}
export const useFacilityQuery = () => {
  return useQuery<InboxStateClinicalFacility>({
    queryKey: facilityQueryKey,
    queryFn: () => ({
      extractedAddress: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
      },
    }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })
}

type SubsetOfClinicalFacility = Pick<FacilityOption, "id" | "name">
export type FetchFacilities = (
  searchTerm: string
) => Promise<SubsetOfClinicalFacility[]>

const updateFacilityInformation = ({
  supplierOrganizationId,
  csrInboxStateId,
}: {
  supplierOrganizationId: string
  csrInboxStateId: string
}): UpdateFacilityInformation => async (
  updatedData: Partial<InboxStateClinicalFacility>
): Promise<void> => {
  const pathToCsrInboxState = `/u/r/${supplierOrganizationId}/inbox_documents/${csrInboxStateId}`
  const requestBody = {
    facility_id: updatedData.facility?.sgid || null,
  }
  await patch(`${pathToCsrInboxState}/facility`, requestBody)
}

const optionRenderFunction = ({ option }) => {
  return (
    <div className={styles.supplierIntakeFacilityData}>
      <div>
        <strong>{option.name}</strong>
        <br />
        <span>
          {option.line1}
          {option.line2 ? " " + option.line2 : ""}, {option.city},{" "}
          {option.state}, {option.zip}
        </span>
        {option.preferredSupplier ? (
          <>
            <br />
            <span>Preferred supplier: {option.preferredSupplier}</span>
            <br />
          </>
        ) : (
          ""
        )}
      </div>
      <div> {option.userCount} Users</div>
    </div>
  )
}

export const FacilityData = ({}: {}) => {
  const queryClient = useQueryClient()
  const csrInboxState = useCsrInboxState()
  const { data } = useQuery<InboxStateClinicalFacility>({
    queryKey: ["csrInboxState", "facility"],
    queryFn: () => ({
      extractedAddress: {
        line1: undefined,
        line2: undefined,
        city: undefined,
        state: undefined,
        zip: undefined,
      },
    }),
    staleTime: infinitySoThatThisWillNotRefetchUnlessSomeoneInvalidatesTheCacheManually,
  })

  useEffect(() => {
    setSelectedValue(
      data?.facility
        ? {
            label: data?.facility?.name,
            value: data?.facility?.sgid,
          }
        : ""
    )
    setRecommendedFacilities([])
  }, [data])

  const [recommendedFacilities, setRecommendedFacilities] = useState(
    [] as FacilityOption[]
  )

  const onChange = updateFacilityInformation({
    supplierOrganizationId: csrInboxState.supplierOrganization.id,
    csrInboxStateId: csrInboxState.id,
  })

  const mutateFacility = useMutation({
    mutationFn: (facility: InboxStateClinicalFacility["facility"]) => {
      return onChange({ facility: facility }).then(() => {
        queryClient.setQueryData<InboxStateClinicalFacility>(
          facilityQueryKey,
          (old) => {
            return {
              extractedAddress: {
                line1: undefined,
                line2: undefined,
                city: undefined,
                state: undefined,
                zip: undefined,
              },
              ...old,
              facility: facility,
            }
          }
        )
      })
    },
  })

  const [searchTerm, setSearchTerm] = React.useState<string>()
  const { data: options } = useQuery({
    queryKey: ["facilities", csrInboxState.id, searchTerm],
    queryFn: async () => {
      if (searchTerm) {
        return fetchFacilities(searchTerm).then((results) => {
          return utilities.groupedFacilities(results, recommendedFacilities)
        })
      }
      return []
    },
  })

  const [selectedValue, setSelectedValue] = React.useState<SelectOption>()

  const fetchFacilities = makeApi("Supplier", csrInboxState.supplier.id)
    .fetchFacilities

  const facility = data || {
    extractedAddress: {
      line1: undefined,
      line2: undefined,
      city: undefined,
      state: undefined,
      zip: undefined,
    },
  }

  return (
    <>
      <CanopyComboboxField
        placeholder="Type to search"
        label="Facility"
        options={options || []}
        multiple={false}
        value={selectedValue}
        onInputChange={setSearchTerm}
        optionRenderFunction={optionRenderFunction}
        onChange={(newValue) => {
          if (!newValue) {
            mutateFacility.mutateAsync(undefined)
            setSelectedValue("")
          } else if (isComboBoxOptionItem(newValue)) {
            mutateFacility.mutateAsync({
              sgid: newValue.value,
              name: newValue.label || "",
            })
            setSelectedValue(newValue)
          } else {
            console.warn(
              "new value on canopycomboboxfield was not an option item? That shouldn't happen",
              newValue
            )
          }
        }}
        noOptionsFoundMessage="No facilities found for that search"
        description={utilities.formatExtractedText(
          facility.extractedName,
          facility.extractedAddress
        )}
      ></CanopyComboboxField>
    </>
  )
}
