// @team @demand-ordering

import React, { useState } from "react"
import Alert from "components/Alert"
import { EVENT_TIME_DATE_FORMAT, format as formatDate } from "utilities/date"
import { navigate } from "utilities/navigation"
import * as styles from "./PendingOrderAlert.module.scss"
import GetPendingOrders from "../graphql/pendingOrdersQuery"
import PendingOrderMigrateToDmeOrder from "../graphql/pendingOrderMigrateToDmeOrderMutation"
import {
  GetPendingOrdersQuery,
  PendingOrder,
} from "graphql/__generated__/graphql"
import { useMutation, useQuery } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

type AlertMessageProps = {
  pendingOrder: PendingOrder
  getTherapyNames: (pendingOrder: { therapyNames?: string | null }) => string
}

const AlertMessage: React.FC<AlertMessageProps> = ({
  pendingOrder,
  getTherapyNames,
}) => {
  const listedTherapyNames = getTherapyNames(pendingOrder)
  return (
    <span>
      {pendingOrder.creator &&
        `${pendingOrder.creator.firstName} ${pendingOrder.creator.lastName}${
          pendingOrder.orderingNpi ? ` (NPI ${pendingOrder.orderingNpi})` : ""
        } `}
      signed a new {listedTherapyNames} order at{" "}
      <time dateTime={pendingOrder.createdAt}>
        {formatDate(pendingOrder.createdAt, EVENT_TIME_DATE_FORMAT)}
      </time>
      <div style={{ fontWeight: "normal" }}>
        Please review the order, choose the product, and confirm delivery
        details to submit it to the HME provider
      </div>
    </span>
  )
}

type Props = {
  patientExternalId: string
  showDmeOrderUrl(dmeOrderId: string): string
  onQueryCompleted?(data: GetPendingOrdersQuery): void
}

const PendingOrderAlert: React.FC<Props> = ({
  patientExternalId,
  showDmeOrderUrl,
  onQueryCompleted,
}) => {
  const [hasError, setHasError] = useState(false)
  const { data } = useQuery(GetPendingOrders, {
    variables: { patientExternalId: patientExternalId },
    onCompleted: onQueryCompleted,
  })

  const pendingOrders: PendingOrder[] = Array.isArray(data?.pendingOrder)
    ? (data?.pendingOrder as PendingOrder[])
    : []

  const [completePendingOrder] = useMutation(PendingOrderMigrateToDmeOrder, {
    onCompleted: ({ pendingOrderMigrateToDmeOrder }) => {
      const dmeOrderId =
        pendingOrderMigrateToDmeOrder?.pendingOrder?.dmeOrder?.id
      if (dmeOrderId !== undefined) {
        navigate(showDmeOrderUrl(dmeOrderId))
      } else {
        setHasError(true)
      }
    },
    onError: () => {
      setHasError(true)
    },
  })

  const [processingOrderId, setProcessingOrderId] = useState<string | null>(
    null
  )

  const handleCompletePendingOrder = async (pendingOrderId: string) => {
    setProcessingOrderId(pendingOrderId)
    try {
      const result = await completePendingOrder({
        variables: { pendingOrderId: pendingOrderId },
      })

      const dmeOrderId =
        result.data?.pendingOrderMigrateToDmeOrder?.pendingOrder?.dmeOrder?.id
      if (dmeOrderId) {
        navigate(showDmeOrderUrl(dmeOrderId))
      } else {
        setHasError(true)
      }
    } catch (error) {
      setHasError(true)
    } finally {
      setProcessingOrderId(null)
    }
  }

  const getTherapyNames = (pendingOrder: {
    therapyNames?: string | null
  }): string => {
    if (!pendingOrder.therapyNames || pendingOrder.therapyNames.trim() === "") {
      return ""
    }

    const therapyNames = pendingOrder.therapyNames.split(",").filter(Boolean)

    if (therapyNames.length === 0) {
      return ""
    } else if (therapyNames.length === 1) {
      return `${therapyNames[0]}`
    } else if (therapyNames.length === 2) {
      return `${therapyNames[0]} and ${therapyNames[1]}`
    } else {
      const lastTherapy = therapyNames.pop()
      return `${therapyNames.join(", ")}, and ${lastTherapy}`
    }
  }

  return pendingOrders.length > 0 ? (
    <>
      {hasError && (
        <CanopyNotice
          variant="error"
          title="Cannot convert pending order"
          actions={[
            {
              href: "mailto:support@parachutehealth.com",
              text: "Please contact support@parachutehealth.com",
            },
          ]}
        />
      )}
      {pendingOrders.map((pendingOrder) => (
        <Alert
          key={pendingOrder.id}
          status="accent"
          className={styles.pendingOrderAlert}
          leftIcon
          bordered
          data-testid="pending-order-alert"
        >
          <AlertMessage
            pendingOrder={pendingOrder}
            getTherapyNames={getTherapyNames}
          />
          <CanopyButton
            onClick={() => handleCompletePendingOrder(pendingOrder.id)}
            size="small"
            loading={processingOrderId === pendingOrder.id}
          >
            View Order
          </CanopyButton>
        </Alert>
      ))}
    </>
  ) : null
}

export default PendingOrderAlert
