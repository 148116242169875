import React, { useEffect, useState } from "react"
import { SubSectionHeader } from "../SectionHeader"
import {
  CanopyComboboxField,
  OptionItem,
} from "@parachutehealth/canopy-combobox-field"
import { searchSkus, createCartItem, removeCartItem } from "./api"
import type { CartItem, Sku } from "./api"
import {
  CsrInboxStateContextType,
  useCsrInboxState,
} from "../../../csrInboxStateContext"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CanopyButton } from "@parachutehealth/canopy-button"
import {
  canopyColorTextDanger,
  canopyColorBackgroundPrimary,
  tableHead,
  skuTable,
  quantityColumn,
  actionColumn,
  itemColumn,
} from "./index.module.scss"
import { useDebounce } from "hooks/useDebounce"
import { QuantityInput } from "./QuantityInput"
import { isSuccess } from "../helpers"

const formatSku = (
  sku: Sku,
  alreadySelectedSkuIds: string[] = []
): OptionItem => ({
  value: sku.id,
  label: sku.description,
  disabled: alreadySelectedSkuIds.includes(sku.id),
})

const useDebouncedValue = (value: string) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  const debouncedSetValue = useDebounce(
    (val) => {
      setDebouncedValue(val)
    },
    300,
    false
  )

  useEffect(() => {
    debouncedSetValue(value)
  }, [value, debouncedSetValue])

  return debouncedValue
}

const useSkuSearch = (
  searchTerm: string,
  supplierOrganizationId: string,
  csrInboxStateId: string
) => {
  const debouncedSearchTerm = useDebouncedValue(searchTerm)

  return useQuery({
    queryKey: [
      "skus",
      supplierOrganizationId,
      csrInboxStateId,
      debouncedSearchTerm,
    ],
    queryFn: () => {
      return searchSkus(
        supplierOrganizationId,
        csrInboxStateId,
        debouncedSearchTerm
      )
    },
    enabled: !!searchTerm,
  })
}

type Props = {
  initialCartItems: CartItem[]
}

export const OrderItems = ({ initialCartItems = [] }: Props): JSX.Element => {
  const csrInboxState = useCsrInboxState()
  const [searchTerm, setSearchTerm] = useState("")
  const [selectedItems, setSelectedItems] = useState<CartItem[]>(
    initialCartItems
  )
  const [feedbackMessage, setFeedbackMessage] = useState<string>()

  const { data: skus = [], isFetching: skuSearchPending } = useSkuSearch(
    searchTerm,
    csrInboxState.supplierOrganization.id,
    csrInboxState.id
  )

  const createCartItemMutation = useMutation({
    mutationFn: async ({ skuId }: { skuId: string; quantity: number }) => {
      return createCartItem(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        skuId
      )
    },
  })

  const removeCartItemMutation = useMutation({
    mutationFn: async (cartItemId: string) => {
      return removeCartItem(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        cartItemId
      )
    },
  })

  const handleSelect = (selectedOption: OptionItem | OptionItem[] | null) => {
    if (!selectedOption) return
    if (Array.isArray(selectedOption)) {
      return
    }

    const newCartItem: CartItem = {
      id: `temp-${selectedOption.value}`,
      skuId: selectedOption.value,
      description: selectedOption.label!,
      quantity: 1,
    }

    setSelectedItems((state) => [...state, newCartItem])

    createCartItemMutation
      .mutateAsync({
        skuId: newCartItem.skuId,
        quantity: newCartItem.quantity,
      })
      .then((result) => {
        if (result.success === true) {
          setSelectedItems((state) =>
            state.map((item) =>
              item.id === newCartItem.id ? result.cartItem : item
            )
          )
        } else {
          setSelectedItems((state) =>
            state.filter((item) => item.skuId !== newCartItem.skuId)
          )
          const errorMessages = Object.values(result.errors).flat()
          setFeedbackMessage(errorMessages.join(", "))
        }
      })

    setSearchTerm("")
  }

  const handleRemove = (itemToRemove: CartItem) => {
    setSelectedItems(
      selectedItems.filter((item) => item.id !== itemToRemove.id)
    )

    removeCartItemMutation.mutateAsync(itemToRemove.id).then((result) => {
      if (!isSuccess(result)) {
        setSelectedItems((state) => [...state, itemToRemove])
        const errorMessages = Object.values(result.errors).flat()
        setFeedbackMessage(errorMessages.join(", "))
      }
    })
  }

  const selectedSkuIds = selectedItems.map((item) => item.skuId)

  return (
    <div className="canopy-mbe-16x ">
      <SubSectionHeader text="Order items" />
      <div className={`canopy-p-8x ${canopyColorBackgroundPrimary}`}>
        <CanopyComboboxField
          label="Product search"
          placeholder="Search by description"
          options={skus.map((sku) => formatSku(sku, selectedSkuIds))}
          onInputChange={setSearchTerm}
          onChange={handleSelect}
          feedbackMessage={feedbackMessage}
          loading={skuSearchPending}
          inputValue={searchTerm}
        />

        {selectedItems.length > 0 && (
          <SelectedSkuTable
            selectedItems={selectedItems}
            onRemove={handleRemove}
            disableRemove={removeCartItemMutation.isLoading}
            csrInboxState={csrInboxState}
          />
        )}
      </div>
    </div>
  )
}

const SelectedSkuTable = ({
  selectedItems,
  onRemove,
  disableRemove,
  csrInboxState,
}: {
  selectedItems: CartItem[]
  onRemove: (itemToRemove: CartItem) => void
  disableRemove: boolean
  csrInboxState: CsrInboxStateContextType
}) => (
  <div className="canopy-mbs-8x">
    <table className={skuTable}>
      <thead className={tableHead}>
        <tr>
          <th className={itemColumn}>Item</th>
          <th className={quantityColumn}>Quantity</th>
          <th className={actionColumn}></th>
        </tr>
      </thead>
      <tbody className="canopy-typography-body-medium">
        {selectedItems.map((item) => (
          <tr key={item.id}>
            <td className={`canopy-pt-8x ${itemColumn}`}>{item.description}</td>
            <td className={`canopy-pt-8x ${quantityColumn}`}>
              <QuantityInput item={item} csrInboxState={csrInboxState} />
            </td>
            <td className={`canopy-pt-8x ${actionColumn}`}>
              <CanopyButton
                className={canopyColorTextDanger}
                variant="tertiary"
                size="small"
                accessibleText={`Remove ${item.description}`}
                onClick={() => onRemove(item)}
                disabled={disableRemove}
              >
                Remove
              </CanopyButton>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)
