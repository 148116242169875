import { ApplicationError, Doctor, Icd10Code } from "sharedTypes"
import { get } from "services/api"
import * as urls from "./urls"
import { handleError } from "utilities/error"

const fakeSearchIcd10Codes = (query: string): Promise<Icd10Code[]> => {
  return new Promise((resolve) => {
    if (!query) return resolve([])
    return resolve([
      {
        code: "DXCODE1",
        codeLabel: "DX.CODE.1",
        description: "Description of diagnosis 1",
      },
      {
        code: "DXCODE2",
        codeLabel: "DX.CODE.2",
        description: "Description of diagnosis 2",
      },
      {
        code: "DXCODE3",
        codeLabel: "DX.CODE.3",
        description: "",
      },
    ])
  })
}

export const searchClinicians = async (term: string): Promise<Doctor[]> => {
  try {
    const response = await get(urls.searchDoctorsUrl(), { term })
    return response.data.results
  } catch (e) {
    handleError(e as ApplicationError)
    return []
  }
}

// TODO: Implement real endpoint after icd10-codes are scoped under payor
// https://parachutehealth.atlassian.net/browse/WF-30842
export const searchIcd10Codes = fakeSearchIcd10Codes
