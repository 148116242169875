import React, { useState } from "react"
import { withBrowserRouter } from "routers/BrowserRouter"
import { employerPrefix } from "utilities/url"
import { Route, Switch, Redirect, useHistory } from "react-router-dom"
import BackToDashboardLink from "./components/BackToDashboardLink"
import ClinicalFacilityProfileName from "./components/ClinicalFacilityProfileName"
import ClinicalFacilityProfileAddress from "./components/ClinicalFacilityProfileAddress"
import ClinicalFacilityProfileInviteUsers from "./components/ClinicalFacilityProfileInviteUsers"
import ClinicalFacilityProfileConfirmUsers from "./components/ClinicalFacilityProfileConfirmUsers"
import ClinicalFacilityProfileThankYou from "./components/ClinicalFacilityProfileThankYou"
import * as styles from "./index.module.scss"
import ClinicalFacilityProfileFaxNumbers from "./components/ClinicalFacilityProfileFaxNumbers"

export type ClinicalFacilityProfile = {
  externalId: string
  name: string
  line1: string
  line2: string
  city: string
  state: string
  zip: string
  faxNumbers: string[]
}

export type User = {
  fullName: string
  email: string
  lastSignInAt: string | null
}

type Props = {
  clinicalFacility: ClinicalFacilityProfile
  users: User[]
  currentStep: string
  steps: string[]
}

const pagesWithHiddenBackToDashboardLink = ["/thank_you"]

const ClinicalFacilityProfileWorkflow = (props: Props): React.JSX.Element => {
  const [facility, setFacility] = useState(props.clinicalFacility)
  const history = useHistory()

  const currentPath = history.location.pathname
  const shouldHideDashboardLink = pagesWithHiddenBackToDashboardLink.includes(
    currentPath
  )

  const includeConfirmUsersStep = props.steps.includes("confirm_users")
  const faxNumbersNext = includeConfirmUsersStep
    ? () => history.push("/confirm_users")
    : () => history.push("/thank_you")

  const totalSteps = () => {
    if (includeConfirmUsersStep) {
      return props.steps.length + 2
    } else {
      return props.steps.length + 1
    }
  }

  return (
    <>
      <BackToDashboardLink
        className={shouldHideDashboardLink && styles.hidden}
      />
      <Switch>
        <Route exact path="/">
          <Redirect to={props.currentStep} />
        </Route>
        <Route
          path="/facility_name"
          render={() => (
            <ClinicalFacilityProfileName
              clinicalFacility={facility}
              onUpdate={setFacility}
              next={() => history.push("/facility_address")}
              totalSteps={totalSteps()}
            />
          )}
        />
        <Route
          path="/facility_address"
          render={() => (
            <ClinicalFacilityProfileAddress
              clinicalFacility={facility}
              onUpdate={setFacility}
              next={() => history.push("/facility_fax_numbers")}
              back={() => history.push("/facility_name")}
              totalSteps={totalSteps()}
            />
          )}
        />
        <Route
          path="/facility_fax_numbers"
          render={() => (
            <ClinicalFacilityProfileFaxNumbers
              clinicalFacility={facility}
              onUpdate={setFacility}
              next={faxNumbersNext}
              back={() => history.push("/facility_address")}
              totalSteps={totalSteps()}
            />
          )}
        />
        {includeConfirmUsersStep && (
          <Route
            path="/confirm_users"
            render={() => (
              <ClinicalFacilityProfileConfirmUsers
                users={props.users}
                clinicalFacility={facility}
                exit={() => history.push("/thank_you")}
                next={() => history.push("/invite_users")}
                back={() => history.push("/facility_fax_numbers")}
                totalSteps={totalSteps()}
              />
            )}
          />
        )}
        {includeConfirmUsersStep && (
          <Route
            path="/invite_users"
            render={() => (
              <ClinicalFacilityProfileInviteUsers
                users={props.users}
                next={() => history.push("/thank_you")}
                back={() => history.push("/confirm_users")}
                totalSteps={totalSteps()}
              />
            )}
          />
        )}
        <Route
          path="/thank_you"
          render={() => (
            <ClinicalFacilityProfileThankYou
              exit={() => (window.location.href = "/")}
            />
          )}
        />
      </Switch>
    </>
  )
}

export default withBrowserRouter(ClinicalFacilityProfileWorkflow, {
  basename: `${employerPrefix()}/complete_profile`,
})
