import React from "react"
import * as styles from "./SalesCollaborationToolsNetwork.module.scss"
import SalesCollaborationToolsScreen from "../components/SalesCollaborationToolsScreen"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"
import { BucketCard } from "applications/SalesCollaborationTools/components/BucketCard"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { Link } from "react-router-dom"
import { BASE_NETWORKS_PATH } from ".."
import { useFavoriteFacilities } from "../context/FavoriteFacilitiesContext"

export interface Props {
  favoriteFacilitiesCount: number
  lowEngagementCount: number
  supplierOrgExternalId: string
  salesTeamCollaborationToolsSavedMainApp?: boolean
  networkExperienceLowEngagement?: boolean
  isSsr: boolean
}

const SalesCollaborationToolsNetwork = (props: Props) => {
  const { favoriteFacilitiesCount } = useFavoriteFacilities()

  return (
    <SalesCollaborationToolsScreen
      {...props}
      screen={Option.Network}
      headerContent={
        <>
          <h1>
            <span className={styles.headerText}>Network</span>
          </h1>
          <div className={styles.subheaderText}>
            Add new facilities, manage existing facilities, and grow your
            Parachute network.
          </div>
          <div className={styles.subheaderText}>
            <b>Note:</b> The data displayed is aggregated across all suppliers
            you are currently employed with.
          </div>
          <CanopyFlex dir="row" gap="6X">
            {props.salesTeamCollaborationToolsSavedMainApp ? (
              <Link
                to={`${BASE_NETWORKS_PATH}/${Option.Saved.toLocaleLowerCase()}`}
              >
                <BucketCard
                  variant="discovery"
                  subtitle="Facilities you've saved."
                  title="Saved"
                  count={favoriteFacilitiesCount}
                />
              </Link>
            ) : null}
            {props.networkExperienceLowEngagement ? (
              <Link
                to={`${BASE_NETWORKS_PATH}/${Option.LowEngagement.toLocaleLowerCase()}`}
              >
                <BucketCard
                  variant="warning"
                  subtitle="Facilities with 0 recent user logins."
                  title="Low engagement"
                  count={props.lowEngagementCount}
                />
              </Link>
            ) : null}
          </CanopyFlex>
        </>
      }
    />
  )
}

export default SalesCollaborationToolsNetwork
