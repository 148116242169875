import { CanopyHeading } from "@parachutehealth/canopy-heading"
import React from "react"

type AuthDiagnosisEditDescriptionProps = {
  isReauthReferral: boolean
  isAuthSubmitted: boolean
}

export const AuthDiagnosisEditDescription: React.FunctionComponent<AuthDiagnosisEditDescriptionProps> = ({
  isReauthReferral,
  isAuthSubmitted,
}) => {
  const primaryCodeInstructions =
    !isReauthReferral && !isAuthSubmitted
      ? "Select one primary diagnosis code (required)"
      : !isReauthReferral
      ? "Edit the primary diagnosis code"
      : "No changes can be made to the primary diagnosis code"

  const otherCodesInstructions =
    !isReauthReferral && !isAuthSubmitted
      ? "Add or remove other diagnosis codes (optional)"
      : "Add or remove other diagnosis codes"

  return (
    <>
      <CanopyHeading size="medium" level={2} className="canopy-mb-2x">
        Diagnosis codes
      </CanopyHeading>
      <p id="dx-codes-description" className="canopy-m-0">
        Allowable edits:
      </p>
      <ul
        aria-labelledby="dx-codes-description"
        className="canopy-m-0 canopy-pl-12x"
      >
        <li>{primaryCodeInstructions}</li>
        <li>{otherCodesInstructions}</li>
      </ul>
    </>
  )
}
