import React, { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { CanopyTextInputField } from "@parachutehealth/canopy-text-input-field"
import { CsrInboxStateContextType } from "../../../csrInboxStateContext"
import { CartItem, updateCartItemQuantity } from "./api"
import { onBlurHelper } from "../helpers"

type QuantityInputProps = {
  item: CartItem
  csrInboxState: Pick<CsrInboxStateContextType, "supplierOrganization" | "id">
}

export const QuantityInput = ({
  item,
  csrInboxState,
}: QuantityInputProps): JSX.Element => {
  const [quantity, setQuantity] = useState<string>(item.quantity.toString())
  const [feedbackMessage, setFeedbackMessage] = useState<string>()

  const updateQuantityMutation = useMutation({
    mutationFn: async (quantity: string) => {
      return updateCartItemQuantity(
        csrInboxState.supplierOrganization.id,
        csrInboxState.id,
        item.id,
        parseInt(quantity, 10)
      )
    },
  })
  const onBlur = onBlurHelper(
    "quantity",
    quantity,
    updateQuantityMutation,
    setFeedbackMessage,
    setQuantity
  )
  return (
    <div>
      <CanopyTextInputField
        type="number"
        inputProps={{
          min: 1,
          max: 10000,
        }}
        defaultValue={quantity}
        label={`Quantity for ${item.description}`}
        hiddenLabel
        feedbackMessage={feedbackMessage}
        onBlur={onBlur}
      />
    </div>
  )
}
