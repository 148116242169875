import { get, post, put } from "services/api"
import * as urls from "applications/Workflow/urls"

export const selectPackage = (pkg) => post(urls.selectPackage(), pkg)
export const selectPackageV2 = (pkg) => post(urls.selectPackageV2(), pkg)
export const selectPackageWithSku = (params) =>
  post(urls.selectPackageWithSkuUrl(), params)
export const searchCatalog = (params) => get(urls.catalog(), params)
export const searchByProduct = (params) =>
  get(urls.searchByProductUrl(), params)
export const initialCatalogDataForSupplier = (supplierId: string) =>
  get(urls.initialCatalogDataUrl(), { supplierId })
export const initialCatalogDataForSearchByProduct = (
  yourOrganizationsSuppliersOnly: boolean
) => get(urls.initialSearchByProductUrl(), { yourOrganizationsSuppliersOnly })
export const initialCatalogDataForAllSuppliers = () =>
  get(urls.initialCatalogDataUrl())
export const searchPackagesWithSkus = (params) =>
  get(urls.searchBySkuUrl(), params)
export const searchPackagesWithSkuCompatibleWithSearchByProduct = (params) =>
  get(urls.searchBySkuCompatibleWithSearchByProductUrl(), params)
export const selectDefaultConsignmentCloset = (params) => {
  return put(urls.selectDefaultConsignmentClosetUrl(), params).then(
    ({ data }) => data
  )
}

export const setOrderType = (params) => put(urls.setOrderType(), params)
