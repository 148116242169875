import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import { CanopyCheckboxInputField } from "@parachutehealth/canopy-checkbox-input-field"
import { formatIcd10CodeOption } from "./utils"
import { useGetRelevantDiagnoses } from "../api/queries/useGetRelevantDiagnoses/useGetRelevantDiagnoses"

interface Props {
  onDiagnosesListChange: (selectedDiagnoses: string[]) => void
  selectedDiagnoses: string[]
}

const RelevantDiagnosesList: React.FC<Props> = ({
  onDiagnosesListChange,
  selectedDiagnoses,
}: Props) => {
  const { pendingOrderId } = useParams()

  const {
    data: { diagnoses: relevantDiagnoses = [] } = { relevantDiagnoses: [] },
  } = useGetRelevantDiagnoses(pendingOrderId)

  const formattedDiagnosesOptions = useMemo(() => {
    return relevantDiagnoses.map((diagnosis) =>
      formatIcd10CodeOption(diagnosis)
    )
  }, [relevantDiagnoses])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target

    const newDiagnosesList = checked
      ? [...selectedDiagnoses, value]
      : selectedDiagnoses.filter((d) => d !== value)

    onDiagnosesListChange(newDiagnosesList)
  }

  return (
    <CanopyCheckboxInputField
      label="Select relevant diagnoses from the patient's chart"
      required={true}
      options={formattedDiagnosesOptions}
      onChange={handleChange}
      value={selectedDiagnoses}
    />
  )
}

export default RelevantDiagnosesList
