import React, { useMemo, useState } from "react"
import groupBy from "lodash/groupBy"
import { unique } from "../../../utilities/array"
import { Select } from "../../../components/form"
import FacilitySuggestion from "./FacilitySuggestion"
import { SELECT_FACILITY_PLACEHOLDER } from "./InternalFacilitySelectForm"
import { FacilityOption } from "../../../sharedTypes"

export type FacilitySelectProps = {
  defaultFacility?: FacilityOption | null
  fetchFacilities(term): Promise<FacilityOption[]>
  recommendedFacilities: FacilityOption[]
  selectFacility(clinicalFacilityId): void
  fieldName?: string
}
function FacilitySelect(props: FacilitySelectProps) {
  const {
    defaultFacility,
    fetchFacilities,
    recommendedFacilities,
    selectFacility,
    fieldName,
  } = props
  const facilitySelect = React.createRef<HTMLInputElement>()
  const [name, setName] = useState<string>("clinicalFacilityId")

  useMemo(() => {
    if (fieldName) {
      setName(fieldName)
    } else {
      setName("clinicalFacilityId")
    }
  }, [fieldName])

  const groupLabel = (key) => {
    switch (key) {
      case "recommended":
        return "Recommended"
      case "fulfillment_agreement":
        return "Facilities on Parachute"
      case "global_npi_service":
        return "Other Facilities"
      case "organization_npi":
        return "Other Facilities"
      default:
        return `Facilities from ${key}`
    }
  }

  const recommendedFacility = (facility) =>
    recommendedFacilities.find((recommended) => recommended.id === facility.id)
  const groupedFacilities = (facilities) => {
    facilities.forEach(
      (facility) =>
        (facility.group = recommendedFacility(facility)
          ? "recommended"
          : facility.source)
    )

    const groups = groupBy(facilities, "group")
    return unique([
      "recommended",
      "fulfillment_agreement",
      ...Object.keys(groups),
    ])
      .filter((key) => groups[key])
      .map((key) => ({
        label: groupLabel(key),
        options: groups[key].map((result) => ({
          label: result.name,
          value: result.id,
          ...result,
        })),
      }))
  }
  const getFacilities = (term) => {
    return fetchFacilities(term).then((results) => {
      return groupedFacilities(results)
    })
  }
  const defaultFacilityOptions = () => {
    const defaultOptions: any[] = []
    if (defaultFacility?.id) {
      const firstOption = {
        label: defaultFacility.name,
        value: defaultFacility.id,
        ...defaultFacility,
      }
      defaultOptions.push(firstOption)
    }
    if (recommendedFacilities.length) {
      const recs = [
        {
          label: "Recommended",
          options: recommendedFacilities.map((option) => ({
            label: option.name,
            value: option.id,
            ...option,
          })),
        },
      ]
      defaultOptions.push(...recs)
    }
    return defaultOptions
  }

  return (
    <Select
      name={name}
      label="Facility"
      isSearchable
      isClearable
      openMenuOnFocus
      placeholder={SELECT_FACILITY_PLACEHOLDER}
      minLength={2}
      options={defaultFacilityOptions()}
      onChange={selectFacility}
      fetchOptions={getFacilities}
      renderOption={(facility) => <FacilitySuggestion facility={facility} />}
      ref={facilitySelect}
      debounce={false}
    />
  )
}

export default FacilitySelect
