// @team @cat-crew

import React from "react"
import { PrescribedQuantity } from "sharedTypes"
import {
  formatNumber,
  formatNumberWithUnit,
} from "../../../../../utilities/formatPrescriptionQuantityData"

interface Props {
  prescribedQuantity: PrescribedQuantity
}

const PrescribedQuantityDetails = ({ prescribedQuantity }: Props) => {
  const DetailItem = ({ label, value }) => {
    return (
      <li className="canopy-mbe-2x font-xs">
        {label}: {value || "Incomplete"}
      </li>
    )
  }

  const application = formatNumberWithUnit(
    prescribedQuantity.dosageMin,
    prescribedQuantity.dosageMax,
    prescribedQuantity.dosageUnitLabel
  )

  const frequencyTimes = formatNumber(
    prescribedQuantity.frequencyTimesMin,
    prescribedQuantity.frequencyTimesMax
  )
  const frequencyInterval = formatNumberWithUnit(
    prescribedQuantity.frequencyIntervalMin,
    prescribedQuantity.frequencyIntervalMax,
    prescribedQuantity.frequencyIntervalUnit
  )
  const frequency =
    !!frequencyTimes && !!frequencyInterval
      ? `${frequencyTimes} times every ${frequencyInterval}`
      : null

  const supplyPeriod = formatNumberWithUnit(
    prescribedQuantity.durationValue,
    prescribedQuantity.durationValue,
    prescribedQuantity.durationUnit
  )

  const total = formatNumberWithUnit(
    prescribedQuantity.calculatedTotalScalarValue,
    prescribedQuantity.calculatedTotalScalarValue,
    prescribedQuantity.calculatedTotalUnitLabel
  )

  return (
    <ul className="canopy-mt-2x canopy-pis-8x font-xs">
      <DetailItem label="Application" value={application} />
      <DetailItem label="Frequency" value={frequency} />
      <DetailItem label="Supply Period" value={supplyPeriod} />
      <DetailItem label={<strong>Dispense Quantity</strong>} value={total} />
    </ul>
  )
}

export default PrescribedQuantityDetails
