import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from "react"

interface FavoriteFacilitiesContextType {
  favoriteFacilitiesCount: number
  modifyFacilitiesCount: (difference: number) => void
}

const FavoriteFacilitiesContext = createContext<
  FavoriteFacilitiesContextType | undefined
>(undefined)

interface FavoriteFacilitiesProviderProps {
  children: ReactNode
  initialCount?: number
}

export const FavoriteFacilitiesProvider: React.FC<FavoriteFacilitiesProviderProps> = ({
  children,
  initialCount = 0,
}) => {
  const [favoriteFacilitiesCount, setFavoriteFacilitiesCount] = useState<
    number
  >(initialCount)

  const modifyFacilitiesCount = useCallback((difference: number) => {
    setFavoriteFacilitiesCount((prevCount) =>
      Math.max(0, prevCount + difference)
    )
  }, [])

  return (
    <FavoriteFacilitiesContext.Provider
      value={{
        favoriteFacilitiesCount,
        modifyFacilitiesCount,
      }}
    >
      {children}
    </FavoriteFacilitiesContext.Provider>
  )
}

export const useFavoriteFacilities = (): FavoriteFacilitiesContextType => {
  const context = useContext(FavoriteFacilitiesContext)
  if (context === undefined) {
    throw new Error(
      "useFavoriteFacilities must be used within a FavoriteFacilitiesProvider"
    )
  }
  return context
}
