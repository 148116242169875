import { useMutation, useQuery } from "@apollo/client"
import { CanopyButton } from "@parachutehealth/canopy-button"
import React, { useState } from "react"
import { CarrierAuthPage_GetCarrierAuthorizationById } from "./graphql/carrierAuthPageGetCarrierAuthById"
import { CanopyNotice } from "@parachutehealth/canopy-notice"
import { withApollo } from "hocs/withApollo"
import {
  CarrierAuthorization,
  CarrierAuthorizationReferralTypesEnum,
  CarrierAuthStatusesEnum,
  CarrierLineItemAuthorization,
  CarrierLineItemAuthorizationUpdateMutationVariables,
} from "graphql/__generated__/graphql"
import { DATE_TIME_FORMAT, format } from "utilities/date"
import { sendError } from "utilities/error"
import { scrollTop } from "utilities/scroll"
import { AuthDetailsAccordion } from "./components/AuthDetailsAccordion/AuthDetailsAccordion"
import {
  AuthEditFormType,
  EditDrawer,
  EditDrawerConfig,
} from "./components/EditDrawer/EditDrawer"
import { OrderLinker } from "./components/OrderLinker/OrderLinker"
import { PayorLineItemEditForm } from "./components/PayorLineItemEditForm/PayorLineItemEditForm"
import { PatientDetails } from "./components/PatientDetails/PatientDetails"
import { CarrierAuthorizationSubmit } from "./graphql/carrierAuthorizationSubmit"
import { CarrierLineItemAuthorizationDelete } from "./graphql/carrierLineItemAuthorizationDelete"
import { CarrierLineItemAuthorizationUpdate } from "./graphql/carrierLineItemAuthorizationUpdate"
import { useEmployer } from "./hooks/useEmployer"
import { calculateAccessByEmployer } from "./utilities/calculateAccessByEmployer"
import { AuthCard } from "./components/AuthCard/AuthCard"
import * as styles from "./index.module.scss"
import { FeatureFlagInput } from "sharedTypes"
import {
  FormActions,
  SubmitLineItemValues,
  SupplierLineItemForm,
} from "./components/SupplierLineItemForm/SupplierLineItemForm"
import { CarrierLineItemAuthorizationCreate } from "./graphql/carrierLineItemAuthorizationCreate"
import { PRE_SUBMIT_REQUEST_DETAILS } from "./utilities/authRequestDetails"
import { FeatureFlagProvider } from "components/FeatureFlagContext"
import { lineItemDetailsByReferralType } from "./constants/lineItemDetails"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { AuthDetailsEditForm } from "./components/AuthDetailsEditForm/AuthDetailsEditForm"
import { CarrierAuthorizationUpdate } from "./graphql/carrierAuthorizationUpdate"
import { useTimer } from "./hooks/useTimer"
import { isNullOrUndefined } from "utilities/isNullOrUndefined"

type PageNotice = {
  status: "success" | "information" | "error"
  message: string
}

interface CarrierAuthorizationRequestProps {
  carrierAuthorizationId: string
  featureFlags: FeatureFlagInput
}

const SupplierPreSubmissionMessage = () => {
  return (
    <>
      <h3 className="canopy-typography-heading-xlarge canopy-mb-0">
        Review & submit authorization request
      </h3>
      <p className="canopy-typography-body-medium canopy-mt-2x canopy-mb-8x">
        Review and complete the missing required information. Changes made on
        this page will NOT be reflected on the order.
      </p>
    </>
  )
}

const MISSING_REQUIRED_INFO_ERROR = "missing_required_info"
const AUTH_SUBMIT_TIMEOUT = 15000 // 15 seconds

const CarrierAuthorizationRequest = ({
  carrierAuthorizationId,
  featureFlags,
}: CarrierAuthorizationRequestProps) => {
  /* STATE */
  const [pageAlert, setPageAlert] = useState<PageNotice | null>(null)
  const [submissionNotice, setSubmissionNotice] = useState<PageNotice | null>(
    null
  )
  const [editDrawerConfig, setEditDrawerConfig] = useState<EditDrawerConfig>({
    open: false,
    formType: null,
    heading: null,
  })

  const resetEditDrawerConfig = () => {
    setEditDrawerConfig({ open: false, formType: null, heading: null })
  }

  const [editItem, setEditItem] = useState<CarrierLineItemAuthorization | null>(
    null
  )

  const [authSubmitPolling, setAuthSubmitPolling] = useState<boolean>(false)

  /* CUSTOM HOOKS*/
  const { currentEmployer, role, isSupplier, isPayor } = useEmployer()
  const {
    startTimer: startPollingTimeout,
    clearTimer: clearPollingTimeout,
  } = useTimer({
    duration: AUTH_SUBMIT_TIMEOUT,
    onExpire: () => {
      handleAuthSubmitFailed(null)
    },
  })

  /* APOLLO HOOKS */
  const { data, startPolling, stopPolling } = useQuery(
    CarrierAuthPage_GetCarrierAuthorizationById,
    {
      variables: { id: carrierAuthorizationId },
      notifyOnNetworkStatusChange: true,
      onError: () => {
        sendError(
          `Error fetching carrier authorization ${carrierAuthorizationId}`
        )
      },
      onCompleted: (result) => {
        const carrierAuth = result?.carrierAuthorization
        if (carrierAuth?.authStatus === CarrierAuthStatusesEnum.PayorInReview) {
          handleAuthSubmitted(carrierAuth.requestSubmitDateTime)
          scrollTop()
        } else if (carrierAuth?.submitFailed) {
          handleAuthSubmitFailed(null)
        } else if (
          carrierAuth?.authStatus ===
          CarrierAuthStatusesEnum.PayorReviewComplete
        ) {
          setSubmissionNotice(null)
        }
      },
    }
  )

  const [createLineItem] = useMutation(CarrierLineItemAuthorizationCreate, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: (data) => {
      const { hcpcs } = data?.carrierLineItemAuthorizationCreate
        ?.carrierLineItemAuthorization as CarrierLineItemAuthorization
      handleMutationComplete({
        status: "success",
        message: `Prior auth line item: ${hcpcs} has been created`,
      })
      scrollTop()
    },
    onError: () => {
      handleMutationComplete({
        status: "error",
        message: `We had trouble creating prior auth line item. Please try again.`,
      })
    },
  })

  const [updateLineItem] = useMutation(CarrierLineItemAuthorizationUpdate, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: (data) => {
      const { hcpcs } = data?.carrierLineItemAuthorizationUpdate
        ?.carrierLineItemAuthorization as CarrierLineItemAuthorization
      handleMutationComplete({
        status: "success",
        message: `Prior auth line item: ${hcpcs} has been updated`,
      })
    },
    onError: () => {
      handleMutationComplete({
        status: "error",
        message: `We had trouble updating prior auth line item ${editItem?.hcpcs}. Please try again.`,
      })
    },
  })

  const [deleteLineItem] = useMutation(CarrierLineItemAuthorizationDelete, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: () => {
      resetEditDrawerConfig()
      handleMutationComplete({
        status: "information",
        message: `Prior auth line item: ${editItem?.hcpcs} has been deleted`,
      })
    },
    onError: (_error) => {
      handleMutationComplete({
        status: "error",
        message: `We had trouble completing your request. Please try again.`,
      })
    },
  })

  const [submitCarrierAuthorization] = useMutation(CarrierAuthorizationSubmit, {
    onCompleted: () => {
      handleCarrierAuthPolling("start")
    },
    onError: (error) => handleAuthSubmitFailed(error.message),
  })

  const [updateCarrierAuthorization] = useMutation(CarrierAuthorizationUpdate, {
    refetchQueries: [CarrierAuthPage_GetCarrierAuthorizationById],
    onCompleted: (result) => {
      console.log("RESULT", result)
    },
    onError: (error) => {
      console.error("ERROR", error)
    },
  })

  /* EVENT HANDLERS */
  const handleMutationComplete = (pageAlert: PageNotice) => {
    setPageAlert(pageAlert)
    setEditItem(null)
    resetEditDrawerConfig()
  }
  const handleAuthUpdate = async (values) => {
    await updateCarrierAuthorization({ variables: { ...values } })
    resetEditDrawerConfig()
  }

  const handleAuthSubmit = () => {
    if (carrierAuthorization?.carrierLineItemAuthorizations?.length === 0) {
      setPageAlert({
        status: "error",
        message: "Cannot be submitted without any line items",
      })
    } else {
      setPageAlert(null)
      setAuthSubmitPolling(true)
      submitCarrierAuthorization({
        variables: {
          carrierAuthorizationId: carrierAuthorizationId,
        },
      })
    }
  }

  const handleCarrierAuthPolling = (directive: "start" | "stop") => {
    if (directive === "stop") {
      stopPolling()
      setAuthSubmitPolling(false)
      clearPollingTimeout()
    } else {
      startPolling(500)
      startPollingTimeout()
      setAuthSubmitPolling(true)
    }
  }

  const handleAuthSubmitted = (requestSubmitDateTime: Date) => {
    handleCarrierAuthPolling("stop")
    setPageAlert(null)
    const prettyPrintSubmitAt = format(
      requestSubmitDateTime.toLocaleString(),
      DATE_TIME_FORMAT
    )
    setSubmissionNotice({
      status: "information",
      message: `Authorization request submitted ${prettyPrintSubmitAt}`,
    })
  }

  const handleAuthSubmitFailed = (errorMessage: string | null) => {
    handleCarrierAuthPolling("stop")
    if (errorMessage === MISSING_REQUIRED_INFO_ERROR) {
      setPageAlert({ status: "error", message: "Missing required info" })
    } else {
      setPageAlert({
        status: "error",
        message:
          "Your request is unable to be submitted at this time. Please try again later.",
      })
    }
    scrollTop()
  }

  const handleLineItemSubmit = async (fields: SubmitLineItemValues) => {
    if (!!editItem) {
      await updateLineItem({
        variables: { ...fields, id: editItem.id },
      })
    } else {
      await createLineItem({
        variables: {
          ...fields,
          hcpcsDetails: fields.hcpcsDetails,
          isMiscHcpcs: fields.isMiscHcpcs || true,
          carrierAuthorizationId: carrierAuthorizationId,
        },
      })
    }
  }

  const handleLineItemDelete = async (lineItemId: string) => {
    await deleteLineItem({ variables: { id: lineItemId } })
  }

  /* METHODS */
  const isLineItemEditable = (lineItem: CarrierLineItemAuthorization) => {
    if (!authEditsEnabled && hasEditAccess) return true

    return lineItem.authEditEligible || !isAuthSubmitted
  }

  const lineItemFormAction = (
    lineItem: CarrierLineItemAuthorization | null
  ) => {
    if (authEditsEnabled && lineItem?.authEditEligible) {
      return FormActions.AUTH_EDIT
    } else if (!!lineItem) {
      return FormActions.EDIT
    } else {
      return FormActions.CREATE
    }
  }

  /* JSX RENDER METHODS */
  const showLineItemEditForm = () => {
    if (isSupplier || authEditsEnabled) {
      return (
        <SupplierLineItemForm
          reauth={
            carrierAuthorization?.referralType ===
            CarrierAuthorizationReferralTypesEnum.Reauthorization
          }
          lineItem={editItem}
          key={editItem?.id}
          formAction={lineItemFormAction(editItem)}
          onSubmit={handleLineItemSubmit}
          onDelete={handleLineItemDelete}
          onCancel={() => {
            resetEditDrawerConfig()
            setEditItem(null)
          }}
        />
      )
    } else if (editItem && isPayor) {
      return (
        <PayorLineItemEditForm
          onCancel={() => {
            resetEditDrawerConfig()
            setEditItem(null)
          }}
          onSubmit={async (
            updatedFields: CarrierLineItemAuthorizationUpdateMutationVariables
          ) => {
            await updateLineItem({ variables: { ...updatedFields } })
          }}
          lineItem={editItem}
          key={editItem.id}
        />
      )
    }
  }

  /* PROPERTIES */
  const isPayorAuthMiscHcpcsFlagOn = featureFlags.payorAuthMiscHcpcs
  const authEditsEnabled = featureFlags.payorAuthEdits
  const carrierAuthorization = data?.carrierAuthorization as CarrierAuthorization
  const carrierMembership = carrierAuthorization?.carrierMembership
  const isAuthSubmitted = !isNullOrUndefined(
    carrierAuthorization?.requestSubmitDateTime
  )
  const hasEditAccess = calculateAccessByEmployer(
    currentEmployer,
    role,
    isAuthSubmitted,
    authEditsEnabled
  )
  const hasSubmitAccess = hasEditAccess && isSupplier && !isAuthSubmitted
  const activeLineItems = carrierAuthorization?.carrierLineItemAuthorizations?.filter(
    (lineItem) => lineItem.isActive
  )

  /* JSX */
  return (
    <FeatureFlagProvider flags={featureFlags}>
      <div className="canopy-mx-8x">
        <h1 className="canopy-typography-heading-2xlarge canopy-mb-0 canopy-my-12x">
          Auth request {carrierAuthorizationId}
        </h1>
        {carrierMembership && (
          <PatientDetails carrierMembership={carrierMembership} />
        )}
        {hasSubmitAccess && <SupplierPreSubmissionMessage />}
        {pageAlert &&
          /*  Typescript gets angry because CanopyNotice doesn't support closable prop for any variants except "success" and "discovery" */
          (pageAlert.status === "success" ? (
            <CanopyNotice
              className="canopy-mb-4x"
              title={pageAlert.message}
              closable={true}
              onClose={() => setPageAlert(null)}
              variant="success"
            />
          ) : (
            <CanopyNotice
              className="canopy-mb-4x"
              title={pageAlert.message}
              variant={pageAlert.status}
            />
          ))}
        {submissionNotice && (
          <CanopyNotice
            title={submissionNotice.message}
            variant={submissionNotice.status}
          />
        )}
        <CanopyFlex
          justifyContent="space-between"
          gap="16X"
          className="canopy-mt-8x"
        >
          <div className={styles.authorizationDetailsContainer}>
            {carrierAuthorization && featureFlags.payorAuthRequestReauth && (
              <div data-testid="auth-details-card">
                <AuthCard
                  title="Auth request details"
                  cardDetails={PRE_SUBMIT_REQUEST_DETAILS}
                  hasEditAccess={hasEditAccess && authEditsEnabled}
                  data={carrierAuthorization}
                  onEditClick={() => {
                    setEditDrawerConfig({
                      open: true,
                      formType: AuthEditFormType.Request,
                      heading: "Edit auth request details",
                    })
                  }}
                  loading={authSubmitPolling}
                />
              </div>
            )}
            {activeLineItems?.map((lineItem, index) => (
              <AuthCard
                data={lineItem}
                key={lineItem.id}
                dmeOrderId={carrierAuthorization?.dmeOrder?.id}
                title={`Prior auth line item: ${lineItem.hcpcs}`}
                cardDetails={lineItemDetailsByReferralType(
                  (carrierAuthorization?.referralType as CarrierAuthorizationReferralTypesEnum) ||
                    CarrierAuthorizationReferralTypesEnum.Initial
                )}
                hasEditAccess={hasEditAccess && isLineItemEditable(lineItem)}
                onEditClick={() => {
                  setEditDrawerConfig({
                    open: true,
                    formType: AuthEditFormType.LineItem,
                    heading: `Prior auth line item ${index + 1}: ${
                      lineItem?.hcpcs
                    }`,
                  })
                  setEditItem(lineItem)
                }}
                loading={authSubmitPolling}
              />
            ))}
            {hasSubmitAccess && isPayorAuthMiscHcpcsFlagOn && (
              <CanopyButton
                variant="secondary"
                size="medium"
                className="canopy-mb-8x"
                fullWidth
                onClick={() => {
                  setEditItem(null)
                  setEditDrawerConfig({
                    open: true,
                    formType: AuthEditFormType.LineItem,
                    heading: "Add line item",
                  })
                }}
              >
                Add line item
              </CanopyButton>
            )}
            {hasSubmitAccess && (
              <CanopyButton
                variant="primary"
                size="medium"
                className="canopy-mt-8x"
                loading={authSubmitPolling}
                onClick={handleAuthSubmit}
              >
                Submit authorization request
              </CanopyButton>
            )}
            {isAuthSubmitted && (
              <div data-testid="auth-details-accordion">
                <AuthDetailsAccordion
                  authorization={carrierAuthorization}
                  data-testid="auth-details-accordion"
                />
              </div>
            )}
          </div>
          {!!carrierAuthorization && (
            <OrderLinker carrierAuthorization={carrierAuthorization} />
          )}
        </CanopyFlex>
        <EditDrawer
          open={editDrawerConfig.open}
          anchor="right"
          heading={editDrawerConfig.heading || ""}
          onClose={() => {
            resetEditDrawerConfig()
          }}
        >
          {editDrawerConfig.formType === AuthEditFormType.LineItem &&
            showLineItemEditForm()}
          {editDrawerConfig.formType === AuthEditFormType.Request && (
            <AuthDetailsEditForm
              authRequest={carrierAuthorization}
              onSubmit={handleAuthUpdate}
              onCancel={() => resetEditDrawerConfig()}
              isAuthSubmitted={isAuthSubmitted}
            />
          )}
        </EditDrawer>
      </div>
    </FeatureFlagProvider>
  )
}

export default withApollo(CarrierAuthorizationRequest)
