import {
  QualificationStatus,
  WorkflowStep,
} from "applications/DoctorPendingOrderWorkflow/sharedTypes"
import { SurveyQuestionsResponse } from "./types"
import { useQuery } from "@tanstack/react-query"
import { surveyQuestionsKey } from "../../utils"
import { get } from "services/api"
import { pendingOrdersTherapySurveyQuestionsUrl } from "applications/DoctorPendingOrderWorkflow/urls"
import { ApplicationError } from "sharedTypes"
import { handleError } from "utilities/error"

export const useGetSurveyQuestions = (
  pendingOrderId: string,
  therapySelectionId: string,
  workflowStep: WorkflowStep
) => {
  return useQuery<SurveyQuestionsResponse>({
    queryKey: surveyQuestionsKey(
      workflowStep,
      pendingOrderId,
      therapySelectionId
    ),
    queryFn: async () => {
      try {
        const response = await get(
          pendingOrdersTherapySurveyQuestionsUrl(
            pendingOrderId,
            therapySelectionId
          ),
          {
            workflowStep,
          }
        )
        return response.data
      } catch (error) {
        handleError(error as ApplicationError)
        return {
          percentageComplete: 0,
          qualificationStatus: QualificationStatus.NotStarted,
          questions: [],
          requiresConfirmation: true,
        }
      }
    },
  })
}
