// @team @cat-crew

import pluralize from "pluralize"

pluralize.addUncountableRule("each")

/**
 * Given a min and max value, formats them as a string range (e.g., "1-2")
 * If both values are equal, a single value is returned as a string
 */
export const formatNumber = (
  min: number | null,
  max: number | null
): string | null => {
  if (min === null || max === null) return null
  return min === max ? `${min}` : `${min}-${max}`
}

/**
 *  Given the string value from `formatNumber`, applies correct pluralization to the unit label
 *
 * - If `min` and `max` are equal (i.e. a single value), the unit is pluralized based on that value
 * - If they differ (i.e. a range), the unit is always pluralized
 *
 * Note: The `pluralize` package does not handle range strings like "1-3",
 * so pluralization is applied manually based on the raw input values.
 */
export const formatNumberWithUnit = (
  min: number | null,
  max: number | null,
  unit: string | null
) => {
  const numberPart = formatNumber(min, max)

  if (!numberPart || !unit) return null

  const isSingleValue = min !== null && max !== null && min === max
  const unitLabel = isSingleValue ? pluralize(unit, min) : pluralize(unit)

  return `${numberPart} ${unitLabel}`
}
