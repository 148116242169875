import React from "react"
import { useParams } from "react-router-dom"
import { diagnosesPath } from "../routes"
import { Layout } from "../Layout/Layout"
import Survey from "../../Workflow/components/Survey"
import { SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import { WorkflowStep } from "../sharedTypes"
import MedicalNecessityStatus from "./MedicalNecessityStatus"
import { useGetSurveyQuestions } from "../api/queries/useGetSurveyQuestions/useGetSurveyQuestions"
import { useSaveAnswer } from "../api/mutations/useSaveAnswer/useSaveAnswer"

const MedicalNecessity = () => {
  const { pendingOrderId, therapySelectionId } = useParams()

  const {
    data: { questions = [] } = { questions: [] },
  } = useGetSurveyQuestions(
    pendingOrderId,
    therapySelectionId,
    WorkflowStep.MedicalNecessity
  )

  const { mutateAsync: saveAnswer } = useSaveAnswer(
    pendingOrderId,
    therapySelectionId,
    WorkflowStep.MedicalNecessity
  )

  const answerQuestion = async (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ): Promise<void> => {
    await saveAnswer({
      answerType,
      answerValue,
      question,
    })
  }

  return (
    <Layout>
      <Layout.Heading
        backlinkPath={diagnosesPath(pendingOrderId, therapySelectionId)}
        backlinkText="Back to diagnosis"
        headingText="Medical Necessity"
        showRequiredFieldKey
      />
      <Layout.Body>
        <Survey
          questions={questions}
          answerQuestion={answerQuestion}
          showMostCommon
        />
        <MedicalNecessityStatus />
      </Layout.Body>
    </Layout>
  )
}

export default MedicalNecessity
