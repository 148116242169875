// @team @demand-ordering

import { gql } from "@apollo/client"
import {
  GetPendingOrdersQuery,
  GetPendingOrdersQueryVariables,
} from "../../../../graphql/__generated__/graphql"
import { TypedDocumentNode } from "@graphql-typed-document-node/core"

const GetPendingsOrders: TypedDocumentNode<
  GetPendingOrdersQuery,
  GetPendingOrdersQueryVariables
> = gql`
  query GetPendingOrders($patientExternalId: String!) {
    pendingOrder(patientExternalId: $patientExternalId) {
      id
      creator {
        firstName
        lastName
      }
      createdAt
      orderingNpi
      therapyNames
    }
  }
`
export default GetPendingsOrders
