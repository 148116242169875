import React from "react"
import { SurveyAnswerType, SurveyQuestion } from "sharedTypes"
import { Link, useParams } from "react-router-dom"
import Survey from "../../Workflow/components/Survey"
import { diagnosesPath } from "../routes"
import { Layout } from "../Layout/Layout"
import { WorkflowStep, QualificationStatus } from "../sharedTypes"
import { useGetSurveyQuestions } from "../api/queries/useGetSurveyQuestions/useGetSurveyQuestions"
import { SurveyQuestionsResponse } from "../api/queries/useGetSurveyQuestions/types"
import { useSaveAnswer } from "../api/mutations/useSaveAnswer/useSaveAnswer"
import { CanopyNotice } from "@parachutehealth/canopy-notice"

interface TherapySelectionProps {
  therapyName: string
}

const placeholderSurveyData: SurveyQuestionsResponse = {
  percentageComplete: 0,
  qualificationStatus: QualificationStatus.NotStarted,
  questions: [],
  requiresConfirmation: true,
}

const Therapy = ({ therapyName }: TherapySelectionProps) => {
  const { pendingOrderId, therapySelectionId } = useParams()

  const { data: survey = placeholderSurveyData } = useGetSurveyQuestions(
    pendingOrderId,
    therapySelectionId,
    WorkflowStep.Rx
  )

  const { mutateAsync: saveAnswer } = useSaveAnswer(
    pendingOrderId,
    therapySelectionId,
    WorkflowStep.Rx
  )

  const answerQuestion: (
    question: SurveyQuestion,
    answerType: SurveyAnswerType,
    answerValue: string
  ) => Promise<void> = async (question, answerType, answerValue) => {
    await saveAnswer({
      answerType,
      answerValue,
      question,
    })
  }

  return (
    <Layout>
      <Layout.Heading headingText="Therapy" showRequiredFieldKey={true} />
      <Layout.Body>
        {therapyName}
        <Survey
          questions={survey.questions}
          answerQuestion={answerQuestion}
          showMostCommon
        />
        {survey.qualificationStatus === QualificationStatus.Disqualified && (
          <CanopyNotice
            variant="error"
            title="Documentation not supported."
            message={survey.disqualifiedMessage}
          />
        )}
        <Link to={diagnosesPath(pendingOrderId, therapySelectionId)}>
          Diagnosis
        </Link>
      </Layout.Body>
    </Layout>
  )
}

export default Therapy
