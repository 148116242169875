import {
  CatalogProduct,
  CatalogProductCustomAttribute,
} from "applications/Cms/types/sharedTypes"
import React from "react"
import {
  destroyProductCustomAttribute,
  rearrangeProductCustomAttributes,
} from "applications/Cms/api/productCustomAttributes"
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core"
import classNames from "classnames"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ConfirmDialog from "applications/Cms/components/ConfirmDialog"
import { DragIndicator } from "@material-ui/icons"
import RowContainer from "../../../../../../components/RowContainer"
import * as styles from "./index.module.scss"
import NoRows from "../../../../../../components/DataGrid/NoRows"
import { AttributesForProductResponse } from "../api"
import AddProductAttributeForm from "../../AddProductAttributeForm"
import { usePolicies } from "../../../../../../contexts/PoliciesContext"
import { CanopyFlex } from "@parachutehealth/canopy-flex"
import { CanopyHeading } from "@parachutehealth/canopy-heading"

export type ProductAttributeTableProps = {
  productCustomAttributes: CatalogProductCustomAttribute[]
  usedAttributeValues: AttributesForProductResponse["usedAttributeValues"]
  product: CatalogProduct
  onRemoveAttribute?: (productCustomAttributeId: number) => void
  onAddAttribute?: () => void
}

const ProductAttributeTable: React.FC<ProductAttributeTableProps> = (
  props: ProductAttributeTableProps
): React.JSX.Element => {
  const {
    product,
    productCustomAttributes,
    onRemoveAttribute,
    onAddAttribute,
    usedAttributeValues,
  } = props

  const { hasPermission } = usePolicies()

  const [tableRows, setTableRows] = React.useState<
    CatalogProductCustomAttribute[]
  >(productCustomAttributes)

  const [confirmationOpen, setConfirmationOpen] = React.useState(false)
  const [attributeToRemove, setAttributeToRemove] = React.useState<
    number | null
  >(null)

  const handleRowMove = (fromIndex: number, toIndex: number) => {
    const row = tableRows[fromIndex]
    const newTableRows = [...tableRows]
    newTableRows.splice(fromIndex, 1)
    newTableRows.splice(toIndex, 0, row)
    setTableRows(newTableRows)
  }

  const handleRowDrop = (): void => {
    void rearrangeProductCustomAttributes(tableRows.map((x) => x.id))
  }

  /**
   * Represents a single row for a single attribute and 0 or more values actually used by SKUs
   */
  const AttributeValues = React.memo(
    (props: { productCustomAttributeId: number }): React.JSX.Element => {
      return (
        <span className="canopy-mbe-2x">
          {usedAttributeValues[props.productCustomAttributeId]?.map((pav) => {
            const label = `${pav.description}${
              pav.patientPreference ? "*" : ""
            }`
            return (
              <Tooltip
                key={label}
                arrow
                title={pav.patientPreference ? "Patient Preference = yes" : ""}
              >
                <Chip
                  size="small"
                  className={classNames(
                    styles.chip,
                    "canopy-mie-2x canopy-mbe-2x"
                  )}
                  label={label}
                />
              </Tooltip>
            )
          })}
        </span>
      )
    }
  )

  const handleRemoveAttributeRow = () => {
    if (attributeToRemove) {
      destroyProductCustomAttribute(attributeToRemove).then(() => {
        if (onRemoveAttribute) {
          onRemoveAttribute(attributeToRemove)
        }
      })
    }
  }

  const openAttributeRemovalConfirmation = (
    productCustomAttributeId: number
  ) => {
    setConfirmationOpen(true)
    setAttributeToRemove(productCustomAttributeId)
  }

  const onCancelRemoveAttribute = () => {
    setConfirmationOpen(false)
    setAttributeToRemove(null)
  }

  const InternalTableBody = () => {
    if (Object.keys(tableRows).length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4} className="canopy-px-0">
            <NoRows
              message="There are no attributes for this product."
              dataGrid={false}
            />
          </TableCell>
        </TableRow>
      )
    } else {
      return (
        <>
          {tableRows.map((row, index) => (
            <RowContainer
              key={row.id}
              row={row}
              index={index}
              rearrangeable={row.permissions?.rearrange}
              onMove={handleRowMove}
              onDrop={handleRowDrop}
              icon={DragIndicator}
            >
              <TableCell component="th" scope="row">
                <a role="link" href={row.customAttribute?.url}>
                  {row.name}
                </a>
              </TableCell>
              <TableCell>
                <AttributeValues productCustomAttributeId={row.id} />
              </TableCell>
              <TableCell>
                {row.permissions?.destroy && (
                  <a
                    role="button"
                    className="color-danger"
                    title="Remove this attribute row"
                    aria-label="Remove this attribute row"
                    onClick={() => {
                      openAttributeRemovalConfirmation(row.id)
                    }}
                  >
                    Remove
                  </a>
                )}
              </TableCell>
            </RowContainer>
          ))}
        </>
      )
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <CanopyFlex
        justifyContent="space-between"
        alignItems="center"
        className="canopy-mbe-12x"
      >
        <div>
          <CanopyHeading level={3} size="large" className="canopy-mbe-4x">
            Attributes
          </CanopyHeading>
          <div className="canopy-typography-body-medium">
            Specifications or characteristics for defining unique product SKUs.
          </div>
        </div>
        {!product.archivedAt &&
          hasPermission("Catalog::ProductCustomAttribute", "create") && (
            <AddProductAttributeForm
              product={product}
              onSave={onAddAttribute}
            />
          )}
      </CanopyFlex>

      <TableContainer>
        <Table
          data-testid="product-detail-attribute-table"
          size="small"
          className={classNames(styles.table)}
        >
          <TableHead className={styles.tableHead}>
            <TableRow>
              <TableCell component="th"></TableCell>
              <TableCell component="th" scope="col">
                Attribute
              </TableCell>
              <TableCell component="th" scope="col">
                Values
              </TableCell>
              <TableCell component="th" scope="col" className={styles.actions}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <InternalTableBody />
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        title="Confirm Attribute removal"
        message="Removing this Attribute will remove it from any SKUs for this Product. This may cause Packages to become unorderable."
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        open={confirmationOpen}
        onConfirm={handleRemoveAttributeRow}
        onCancel={onCancelRemoveAttribute}
        handleClose={onCancelRemoveAttribute}
      />
    </DndProvider>
  )
}

export default ProductAttributeTable
