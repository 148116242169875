import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import debounce from "awesome-debounce-promise"
import { MaterialThemeProvider } from "../../../themes/theme"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro"
import { isTest } from "../../../utilities/environment"
import ParachuteMuiDataGridPro from "../../../components/ParachuteMuiDataGrid/ParachuteMuiDataGrid"
import ParachuteMuiDataGridContainer from "../../../components/ParachuteMuiDataGrid/ParachuteMuiDataGridContainer"
import useServerSideDataGrid from "../../Cms/hooks/useServerSideDataGrid"
import {
  getLowEngagementFacilities,
  getSalesCollaborationFacilities,
  getSavedFacilities,
} from "../api"
import { withBrowserRouter } from "../../../routers/BrowserRouter"
import {
  SalesCollaborationFacilitiesOptions,
  SalesCollaborationFacilitiesRow,
  SalesCollaborationToolsTableProps,
} from "../sharedTypes"
import { handleError } from "../../../utilities/error"
import { ApplicationError } from "../../../sharedTypes"
import { timeAgoInWords } from "../../../utilities/date"
import Tooltip from "components/Tooltip"
import * as styles from "./SalesCollaborationTools.module.scss"
import { makeStyles } from "@material-ui/styles"
import * as tokens from "@parachutehealth/canopy-tokens-typography"
import { FavoriteIconButton } from "./FavoriteIconButton"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"

const PAGE_SIZE = 25

const useStyles = makeStyles({
  root: {
    borderTop: "none",
    "& .MuiDataGrid-columnsContainer": {
      // fix weird bug in MUI with the header jumping from top align to centered as you change width
      justifyContent: "center",
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "flex",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      // overrides to allow multi-line header without the hardcoded height + lineHeight of 56 from MUI
      lineHeight: tokens.canopyTypographyBodySmallLineHeight,
      overflow: "visible",
      whiteSpace: "normal",
      // wrap text to a second line if needed, but use ellipsis rather than 3+ lines
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  },
})

const renderCell = (params: GridRenderCellParams) => {
  return (
    <div className={styles.cellText}>
      <Tooltip overlay={params.value}>
        <div className={styles.cellText}>{params.value}</div>
      </Tooltip>
    </div>
  )
}

const defaultColumnProps: Partial<GridColDef> = {
  sortable: false, // do server side sorting instead
  hide: false,
  flex: 2,
  headerAlign: "left",
  align: "left",
  valueFormatter: (params) => params.value?.toString(),
}

const savedColumn = {
  ...defaultColumnProps,
  field: "favorite",
  headerName: "Save",
  type: "boolean",
  flex: 0,
}
const facilityNameColumn = {
  ...defaultColumnProps,
  field: "facilityName",
  headerName: "Facility Name",
  type: "string",
  cellClassName: "canopy-typography-font-weight-bold",
  renderCell,
  flex: 4,
}
const joinedParachuteColumn = {
  ...defaultColumnProps,
  field: "onParachute",
  headerName: "Joined Parachute",
  type: "date",
  valueFormatter: (params) => {
    const timeAgo = timeAgoInWords(params.value?.toString() || "")
    return timeAgo === "" ? "-" : timeAgo
  },
}
const lastUserLoginColumn = {
  ...defaultColumnProps,
  field: "lastUserLogin",
  headerName: "Last user login",
  type: "date",
  valueFormatter: (params) => {
    const timeAgo = timeAgoInWords(params.value?.toString() || "")
    return timeAgo === "" ? "-" : timeAgo
  },
  sortable: true,
}

const sharedColumns: GridColDef[] = [
  {
    ...defaultColumnProps,
    field: "avgMonthlyOrders",
    headerName: "Avg. monthly orders (last 3 months)",
    type: "number",
    sortable: true,
  },
  {
    ...defaultColumnProps,
    field: "outstandingOrdersCount",
    headerName: "Open Orders",
    type: "number",
    sortable: true,
  },
  {
    ...defaultColumnProps,
    field: "totalOrdersCount",
    headerName: "Orders (all time)",
    sortable: true,
  },
  {
    ...defaultColumnProps,
    field: "salesRep",
    headerName: "Sales Rep(s)",
    type: "string",
    renderCell,
  },
  {
    ...defaultColumnProps,
    field: "facilityAddress",
    headerName: "Address",
    type: "string",
    renderCell,
    flex: 3,
  },
]

const lowEngagementColumns: GridColDef[] = [
  facilityNameColumn,
  lastUserLoginColumn,
  ...sharedColumns,
]

const defaultColumns: GridColDef[] = [
  facilityNameColumn,
  joinedParachuteColumn,
  ...sharedColumns,
]

const generateParamsRef = (params) =>
  `${params.page}-${params.sort}-${params.search}-${params.zip_code}-${params.external_sales_user_external_ids}`

const SalesCollaborationToolsTable = (
  props: SalesCollaborationToolsTableProps
): React.JSX.Element => {
  const {
    filters,
    onError,
    page,
    supplierOrgExternalId,
    salesTeamCollaborationToolsSavedMainApp,
    screen,
  } = props

  const queryParamsRef = useRef<string>()

  const getFacilities = useMemo(() => {
    if (screen === Option.Saved) {
      return getSavedFacilities
    } else if (screen === Option.LowEngagement) {
      return getLowEngagementFacilities
    } else {
      return getSalesCollaborationFacilities
    }
  }, [screen])

  const renderSavedIcon = useCallback(
    (params: GridRenderCellParams) => {
      const clinicalFacilityId = params.row.externalId
      const saved = params.value as boolean

      return (
        <FavoriteIconButton
          clinicalFacilityId={clinicalFacilityId}
          supplierOrgExternalId={supplierOrgExternalId}
          onError={onError}
          favorite={saved}
          name={params.row.facilityName}
        />
      )
    },
    [supplierOrgExternalId, onError]
  )

  const classes = useStyles()

  const defaultOptions: SalesCollaborationFacilitiesOptions = {
    page: page || 1,
    supplierOrgExternalId: supplierOrgExternalId,
    search: filters?.search,
    zip_code: filters?.zipCodes,
    external_sales_user_external_ids: filters?.salesReps,
  }
  const { search, zipCodes, salesReps } = filters ?? {}
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<{
    rows: SalesCollaborationFacilitiesRow[]
    totalCount: number
  }>({
    rows: [],
    totalCount: 0,
  })

  // only allow sort if single page
  const canSort = 0 < data.totalCount && data.totalCount <= PAGE_SIZE

  // add in saved column if feature flag enabled
  const allColumns = useMemo(() => {
    const screenColumns =
      screen === Option.LowEngagement ? lowEngagementColumns : defaultColumns

    const columns = canSort
      ? screenColumns
      : screenColumns.map((col) => ({ ...col, sortable: false }))

    return salesTeamCollaborationToolsSavedMainApp
      ? [
          {
            ...savedColumn,
            renderCell: renderSavedIcon,
          },
          ...columns,
        ]
      : columns
  }, [
    renderSavedIcon,
    salesTeamCollaborationToolsSavedMainApp,
    canSort,
    screen,
  ])

  const fetchFunction = async (params): Promise<void> => {
    try {
      const paramsKey = generateParamsRef(params)
      queryParamsRef.current = paramsKey

      const data = await getFacilities(supplierOrgExternalId, params)

      const isLatestQueryParams = queryParamsRef.current === paramsKey
      // only update data if the query params haven't changed since the fetch started (i.e. we don't have a newer fetch that's overriding this one)
      if (isLatestQueryParams) {
        setData({
          rows: data.rows,
          totalCount: data.totalCount,
        })
      }
    } catch (e) {
      handleError(e as ApplicationError)
    }
  }

  const beforeFetch = () => {
    setLoading(true)
  }
  const afterFetch = () => {
    setLoading(false)
  }

  const {
    options,
    filterModel,
    handleSortModelChange,
    handleFilterChange,
    handlePageChange,
  } = useServerSideDataGrid<SalesCollaborationFacilitiesOptions>({
    defaultFilterModel: {},
    defaultOptions,
    columnDefinitions: allColumns,
    trackHistory: false,
    fetchFunction,
    beforeFetch,
    afterFetch,
  })

  const prevZipCodes = useRef(zipCodes)
  const prevSalesReps = useRef(salesReps)
  const prevSearch = useRef(search)
  const updateSearch = useCallback(
    (newQuery) => {
      handleFilterChange("search", newQuery)
    },
    [handleFilterChange]
  )

  const debouncedUpdateSearch = useMemo(
    () => debounce(updateSearch, 200, { leading: false }),
    [updateSearch]
  )

  useEffect(() => {
    if (
      search !== prevSearch.current &&
      // don't search for 1-2char queries
      (!search?.length || search?.length > 2)
    ) {
      debouncedUpdateSearch(search)
      prevSearch.current = search
    }
  }, [search, debouncedUpdateSearch])

  useEffect(() => {
    if (zipCodes !== prevZipCodes.current) {
      handleFilterChange("zip_code", zipCodes)
      prevZipCodes.current = zipCodes
    }
  }, [zipCodes, handleFilterChange])

  useEffect(() => {
    if (salesReps !== prevSalesReps.current) {
      handleFilterChange("external_sales_user_external_ids", salesReps)
      prevSalesReps.current = salesReps
    }
  }, [salesReps, handleFilterChange])

  return (
    <div style={{ maxWidth: "inherit" }}>
      <MaterialThemeProvider>
        <ParachuteMuiDataGridContainer>
          <ParachuteMuiDataGridPro
            classes={{
              root: classes.root,
            }}
            disableSelectionOnClick={true}
            disableColumnFilter
            columns={allColumns}
            autoHeight
            filterMode="server"
            filterModel={filterModel}
            disableVirtualization={isTest()} // Needs to be true for tests to work but ideally false in production, esp. for higher row counts
            rows={data.rows}
            rowCount={data.totalCount}
            density="standard"
            loading={loading}
            sortModel={options.sort}
            sortingMode="client"
            onSortModelChange={handleSortModelChange}
            page={(options.page || 1) - 1} // account for DataGrid's zero-based indexing
            onPageChange={(page) => {
              handlePageChange(page + 1)
            }} // account for DataGrid's zero-based indexing
            getRowId={(row) => row.externalId}
            pagination={true}
            paginationMode="server"
            pageSize={PAGE_SIZE}
            rowsPerPageOptions={[PAGE_SIZE]}
            rowHeight={62}
          />
        </ParachuteMuiDataGridContainer>
      </MaterialThemeProvider>
    </div>
  )
}

export default withBrowserRouter(SalesCollaborationToolsTable, {})
